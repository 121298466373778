import { Component, inject, OnDestroy, OnInit, input, output, computed } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';
import { PersonMatcherService, SimpleConditionForm } from '../person-matcher.service';
import { Subscription } from 'rxjs';
import { DropdownItem } from 'components/dropdown/dropdown.model';
import { Chip, ChipListComponent } from 'components/chip-list/chip-list.component';
import { DropdownComponent } from 'components/dropdown/dropdown.component';
import { IconComponent } from 'components/icon/icon.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@AutoUnsubscribe()
@Component({
    selector: 'app-simple-condition',
    templateUrl: './simple-condition.component.html',
    styleUrls: ['./simple-condition.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TranslateModule,

        ChipListComponent,
        DropdownComponent,
        IconComponent,
    ],
})
export class SimpleConditionComponent implements OnInit, OnDestroy {
    private readonly personMatcherService = inject(PersonMatcherService);
    private readonly translate = inject(TranslateService);
    private readonly fb = inject(FormBuilder);

    readonly everyone = input<boolean>();
    readonly everyoneLabel = input<string>();
    readonly formGroup = input<SimpleConditionForm>();
    readonly grouped = input<boolean>();
    readonly canAdd = input<boolean>();
    readonly canRemove = input<boolean>(true);

    readonly remove = output<void>();
    readonly add = output<void>();

    key = this.personMatcherService.key$.value;
    negate = this.personMatcherService.negate$.value;
    readonly value = this.personMatcherService.formValues;
    private subscriptions: Subscription[] = [];

    negateForm?: FormControl<string>;

    readonly everyoneGroup = this.fb.group({
        key: ['everyone'],
        negate: [false],
        value: [['everyone']],
        self: [false],
    });
    readonly everyoneKey: DropdownItem[] = [{ key: 'everyone', label: 'configuration.channels.people.people' }];
    readonly everyoneNegate: DropdownItem[] = [{ key: 'include', label: 'rField.filters.include' }];
    readonly everyoneValue = computed(() =>
        [{ id: 'everyone', name: this.everyoneLabel() ?? this.translate.instant('rField.people.everyone') }] satisfies Chip[],
    );

    ngOnInit() {
        const formGroup = this.formGroup();
        const value = this.everyone() ? 'include' : formGroup.controls.self.value ? 'self' :
            formGroup.controls.negate.value ? 'exclude' : 'include';
        this.negateForm = this.fb.control(value);
        this.negateForm.valueChanges.subscribe(value => {
            this.formGroup().controls.negate.setValue(value === 'exclude');
            this.formGroup().controls.self.setValue(value === 'self');
        });
        this.subscriptions = [
            this.personMatcherService.key$.subscribe(key => {
                this.key = key;
            }),
            this.personMatcherService.negate$.subscribe(negate => {
                this.negate = negate;
            }),
        ];
    }

    checkNegate() {
        // reset control's value when condition key is changed
        const formGroup = this.formGroup();
        formGroup.controls.value.setValue([]);
        const key = formGroup.get('key').value;
        const negate = formGroup.get('negate').value;
        const self = formGroup.get('self').value;
        const excludeValue = negate ? 'exclude' : 'include';
        const value = self ? 'self' : excludeValue;
        const inUse = this.negate[key]?.find(v => v.key === value)?.control;
        if (inUse && inUse !== formGroup) {
            const notInUse = this.negate[key].find(v => !v.disabled);
            this.negateForm.setValue(notInUse.key);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(v => v?.unsubscribe());
    }
}
