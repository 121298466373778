import { i18nDialect, i18nLocale } from '@weavix/models/src/translate/translate';
import { normalizeLanguage } from './normalize-language';

/**
 * Parses an input string into our separate locale and dialect parts.
 *
 * The input may be a full language code (e.g., "en-US"), a locale (e.g., "en"), or null/undefined.
 * If no dialect part is provided, the default dialect is assumed.
 *
 * See also: {@link normalizeLanguage}.
 */
export function parseLanguage(input: string | null | undefined): [i18nLocale | undefined, i18nDialect | undefined] {
    if (!input) return [undefined, undefined];
    const language = normalizeLanguage(input);
    const [locale, dialect] = language?.split('-') ?? [undefined, undefined];
    return [locale as i18nLocale | undefined, dialect as i18nDialect | undefined];
}
