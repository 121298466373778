@if (modal.isOpen) {
    <app-modal
        [modalInput]="modal"
        [showClose]="true"
        (modalCloseOutput)="handleModalClose()"
    >
        <app-people-list
            class="modal"
            [data]="data"
            [isFullView]="true"
            [displayLoading]="true"
        ></app-people-list>
    </app-modal>
}
