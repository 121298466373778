import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FilterHeaderComponent } from 'components/filter-header/filter-header.component';

@Component({
    selector: 'app-network-coverage-detail',
    templateUrl: './network-coverage-detail.component.html',
    styleUrls: ['./network-coverage-detail.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,

        FilterHeaderComponent,
    ],
})
export class NetworkCoverageDetailComponent {
    @Input() averages: { [key: string]: number } = {};
    @Input() metricsToDisplay: string[] = [];
    @Input() surveyCount: number = 0;
}
