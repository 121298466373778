import { Context, createStore, ExtendedModel, idProp, model, Model, prop } from '@weavix/mobx';

@model('FeatureFlag')
export class FeatureFlag extends Model({
    id: idProp, // featureId-accountId-facilityId
    featureId: prop<string>(),
    accountId: prop<string>(),
    facilityId: prop<string>(),
    enabled: prop<boolean>(),
    lastUpdated: prop<Date>(),
}) {

}

const base = createStore<FeatureFlag>('feature-flags', FeatureFlag);

export const featureFlagsContext = base.context as Context<FeatureFlagsStore>;

@model('FeatureFlagsStore')
export class FeatureFlagsStore extends ExtendedModel(base.Store, {
}) {

}
