import { idProp, Model, model, prop } from '@weavix/mobx';

@model('Facility')
export class Facility extends Model({
    id: idProp,
    accountId: prop<string>(),
    name: prop<string>(),
    location: prop<number[]>(),
    bounds: prop<number[][]>(),
    zoom: prop<number>(),
    tags: prop<string[]>(),
    address: prop<string>(),
    timezone: prop<string>(),
}) {
    get lat() {
        return this.location?.[1];
    }

    get lng() {
        return this.location?.[0];
    }
}
