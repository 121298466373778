import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Company } from '@weavix/models/src/company/company';
import { IconComponent } from 'components/icon/icon.component';
import { Item, ItemType } from 'weavix-shared/models/item.model';

@Component({
    selector: 'app-item-row-display',
    templateUrl: './item-row-display.component.html',
    styleUrls: ['./item-row-display.component.scss'],
    standalone: true,
    imports: [
        CommonModule,

        IconComponent,
    ],
})
export class ItemRowDisplayComponent {
    @Input() item: Item & { itemType: ItemType, owner: Company };
    @Output() clickEmit = new EventEmitter<Item>();

    click() {
        this.clickEmit.emit(this.item);
    }
}
