<app-loading [loading]="loadingCount() > 0" [theme]="'transparent'" />

@if (loadingCount() <= 0) {
    <div  class="wrapper flex1 flexColumn jcFS" style="overflow-y:auto;">
        @if (walt(); as walt) {
            <div (click)="handleClose()" class="intro">
                <app-icon
                    class="intro-close"
                    [faIcon]="'fa fa-chevron-left'"
                />

                <p class="intro-name">{{walt.id}}</p>
            </div>

            <div class="walt-intro flexRow jcC">
                <app-icon [icon]="waltIcon" fontSize="60px" />
                <div class="walt-info">
                    <p class="name">{{walt.id}}</p>

                    @if (!hasDeviceNetworkStatusPerms()) {
                        <!-- Only show if they don't have the new Network Status page. -->
                        @if (walt.macAddress) {
                            <div class="header-info">
                                <p class="header-info">{{'walt.mac' | translate }}:</p>
                                <p class="header-info-light">{{walt.macAddress}}</p>
                            </div>
                        }
                        @if (walt.btAddress) {
                            <div class="header-info">
                                <p class="header-info">{{'walt.bt-address' | translate }}:</p>
                                <p class="header-info-light">{{walt.btAddress}}</p>
                            </div>
                        }
                        @if (walt.network) {
                            <div  class="header-info">
                                <p class="header-info">{{'walt.current' | translate }}:</p>
                                <p class="header-info-light">{{walt.network.toUpperCase()}}</p>
                            </div>
                        }
                    }
                </div>

                @if (hasDeviceManagementEditPerms()) {
                    <button class="blue-lt small w100p mT10 flexRow jcC" (click)="playSound()">
                        <app-icon [faIcon]="'fas fa-volume-up'" [fontSize]="'20px'" />
                        <p class="pL5">{{'walt.play-sound' | translate}}</p>
                    </button>
                }

                <span class="assigned-text">{{'walt.assigned-site' | translate}}</span> 
                <app-dropdown
                    [ngModel]="waltFacility()"
                    [dropdownInput]="facilityDropdown()"
                    (dropdownOutput)="onFacilityOutput($event)"
                    [style]="{width: '100%'}"
                    [readonly]="!hasDeviceManagementEditPerms() || facilities().length === 1"
                />

                @if (walt.loggedIn && canLogOut() && hasDeviceManagementEditPerms()) {
                    <button class="blue-lt small w100p mT10 flexRow jcC"
                        (click)="remoteLogout()"
                    >
                        <app-icon [faIcon]="'fa fa-sign-out-alt'" fontSize="20px" />
                        <p class="pL5">{{'generics.logout' | translate}}</p>
                    </button>
                }
            </div>

            @if (!hasDeviceNetworkStatusPerms()) {
                <!-- Only show if they don't have the new Network Status page. -->
                @if (walt.currentNetwork && !walt.currentNetwork.clientDisconnectedDate) {
                    <div class="info-box">
                        <p class="info-box-title">{{'walt.network' | translate}}</p>
                        <div class="info-box-content">
                            <app-icon [faIcon]="walt.network === 'wifi' ? 'fas fa-wifi' : 'fas fa-signal-alt'" />
                            <p class="pL5">{{walt.currentNetwork?.ssid}}</p>
                        </div>
                        <div class="header-info">
                            <p class="header-info">{{ 'walt.ip' | translate }}:</p>
                            <p class="header-info-light">{{walt?.currentNetwork?.ip}}</p>
                        </div>
                        <div class="header-info">
                            <p class="header-info">{{ 'walt.mac' | translate }}:</p>
                            <p class="header-info-light">{{walt?.currentNetwork?.mac?.toUpperCase()}}</p>
                        </div>
                    </div>
                }

                @if (walt.simOne?.iccid && !walt.simOne.clientDisconnectedDate) {
                    <div class="info-box">
                        <p class="info-box-title">{{'walt.sim-one' | translate}}</p>
                        <div class="header-info">
                            <p class="header-info">{{ 'walt.carrier' | translate }}:</p>
                            <p class="header-info-light">{{walt?.simOne?.carrier}}</p>
                        </div>
                        <div class="header-info">
                            <p class="header-info">{{ 'walt.iccid' | translate }}:</p>
                            <p class="header-info-light">{{walt?.simOne?.iccid}}</p>
                        </div>
                        <div class="header-info">
                            <p class="header-info">{{ 'walt.imei' | translate }}:</p>
                            <p class="header-info-light">{{walt?.simOne?.imei}}</p>
                        </div>
                    </div>
                }

                @if (walt.simTwo?.iccid && !walt.simTwo.clientDisconnectedDate) {
                    <div class="info-box">
                        <p class="info-box-title">{{'walt.sim-two' | translate}}</p>
                        <div class="header-info">
                            <p class="header-info">{{ 'walt.carrier' | translate }}:</p>
                            <p class="header-info-light">{{walt?.simTwo?.carrier}}</p>
                        </div>
                        <div class="header-info">
                            <p class="header-info">{{ 'walt.iccid' | translate }}:</p>
                            <p class="header-info-light">{{walt?.simTwo?.iccid}}</p>
                        </div>
                        <div class="header-info">
                            <p class="header-info">{{ 'walt.imei' | translate }}:</p>
                            <p class="header-info-light">{{walt?.simTwo?.imei}}</p>
                        </div>
                    </div>
                }

                @if (walt.wrangler?.id) {
                    <div class="info-box">
                        <p class="info-box-title">{{'walt.wrangler' | translate}}</p>
                        <div class="info-box-content" style="flex-direction:column;">
                            <p class="pL5">{{walt.wrangler.id}}</p>
                            <p class="pL5">{{'walt.slot' | translate: { id: walt.wrangler.slot } }}</p>
                        </div>
                    </div>
                }
            }

            @if (walt.battery >= 0) {
                <div class="info-box">
                    <p class="info-box-title">{{'walt.battery' | translate}}</p>
                    <div class="info-box-content">
                        <app-icon [faIcon]="batteryIcon()" [fontSize]="'30px'" />
                        <p class="pL5">{{walt.battery | percent}}</p>
                    </div>
                </div>
            }

            @if (walt.version) {
                <div class="info-box">
                    <p class="info-box-title">{{'walt.version' | translate}}</p>
                    <div class="info-box-content">
                        <p class="pL5">{{walt.version}}</p>
                    </div>
                </div>
            }

            @if (person()?.fullName) {
                <div class="info-box">
                    <p class="info-box-title">{{'walt.assigned-to' | translate}}</p>
                    <div class="info-box-content">
                        <app-avatar
                            [avatarInput]="{
                                height: 40,
                                width: 40,
                                img: person().avatarFile,
                                placeholder: { 'icon': 'fas fa-user', 'iconColor': 'white' }
                            }"
                            [readonly]="true"
                            class="pT10 pB5"
                        />
                    </div>
                    <div class="info-box-content">
                        <p>{{person().fullName}}</p>
                    </div>
                </div>
            }

            @if (company(); as company) {
                <div class="info-box">
                    <p class="info-box-title">{{'walt.company' | translate}}</p>
                    <div class="info-box-content">
                        @if (company.logo) {
                            <img class="pT10 pB5" style="max-width: 60px;max-height: 60px;" [src]="company.logo" [alt]="company.name" [title]="company.name">
                        }
                    </div>
                    <div class="info-box-content">
                        <p>{{ company.name }}</p>
                    </div>
                </div>
            }

            @if (walt.locationUpdated) {
                <div class="info-box">
                    <p class="info-box-title">{{'walt.last-updated' | translate}}</p>
                    <div class="info-box-content">
                        <p>{{walt.locationUpdated | timeAgo}}</p>
                    </div>
                </div>
            }
        } @else {
            <div class="flexColumn jcC h100p w100p">
                <p class="no-data">{{'ERRORS.GENERICS.LOAD-DATA' | translate}}</p>
            </div>
        }
    </div>
}

@if (changeFacility()) {
    <app-modal
        [modalInput]="changeFacility()"
        (modalCloseOutput)="updateWaltFacility($event)"
    />
}

