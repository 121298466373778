import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemeService } from 'weavix-shared/services/themeService';
import { Avatar } from 'components/avatar/avatar.model';
import { Person } from 'weavix-shared/models/person.model';
import { UserProfile } from 'weavix-shared/models/user.model';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { css } from 'weavix-shared/utils/css';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from 'components/avatar/avatar.component';
import { IconComponent } from 'components/icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-person-row-display',
    templateUrl: './person-row-display.component.html',
    styleUrls: ['./person-row-display.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,

        AvatarComponent,
        IconComponent,
    ],
})
export class PersonRowDisplayComponent implements OnInit {
    @Input() person: Person;
    @Input() size: 'small' | 'large' = 'small';

    @Output() clickEmit = new EventEmitter<Person>();

    avatarInput: Avatar;
    userProfile: UserProfile;

    lightTheme: boolean;

    constructor(private profileService: ProfileService) {}

    async ngOnInit() {
        this.lightTheme = ThemeService.getLightTheme();
        this.userProfile = await this.profileService.getUserProfile(this);

        this.avatarInput = {
            height: this.size === 'small' ? 30 : 60,
            width: this.size === 'small' ? 30 : 60,
            img: this.person.avatarFile,
            circular: true,
            placeholder: {
                icon: 'fas fa-user',
                iconColor: css.colors.WHITE,
                backgroundColor: css.colors.GRAY,
            },
        };
    }

    handleChatPress(event: Event): void {
        event.stopPropagation();
    }

    click() {
        this.clickEmit.emit(this.person);
    }
}
