<app-loading [loading]="loading" [theme]="'transparent'"></app-loading> 

<div *ngIf="!loading" class="wrapper flex1 flexColumn jcFS">
    <div class="flexColumn jcC h100p w100p" *ngIf="!person">
        <p class="no-data">{{'ERRORS.GENERICS.LOAD-DATA' | translate}}</p>
    </div>

    <ng-container *ngIf="person">
        <div *ngIf="!removeBackButton"
            (click)="handleClose()"
            class="intro"
        >
            <app-icon
                class="intro-close"
                [faIcon]="'fa fa-chevron-left'"
            ></app-icon>

            <p *ngIf="person.fullName" class="intro-name">{{person.fullName}}</p>
        </div>

        <div class="person-avatar-container flexRow jcC">
            <app-avatar-person 
                [personId]="person.id" 
                [avatarInput]="avatarInput"
                [showAvailability]="false"
            ></app-avatar-person>
        </div>

        <div class="person-info">
            <p *ngIf="person.fullName" class="name">{{person.fullName}}</p>
            <p class="crafts" *ngIf="personCrafts">{{personCrafts}}</p>

            <div *ngIf="person.company.logo" class="logo">
                <img [src]="person.company.logo" />
            </div>
        </div>

        <div *ngIf="userProfile?.personId !== person?.id && canDirectory" class="actions flexColumn">
            <button class="action-button blue-lt small" (click)="radioClick()">
                <app-icon class="icon" [faIcon]="'icon-walt'"></app-icon>
                <p class="text">{{'person-detail.radio' | translate}}</p>
            </button>
        </div>
    </ng-container>

    <app-gas-monitor-readings
        *ngIf="!chartsLoading"
        [person]="person"
    ></app-gas-monitor-readings>
    
    <div class="person-charts flex1 flexColumn jcFS">
        <ng-container *ngIf="chartsLoading">
            <app-loader></app-loader>
        </ng-container>
        <ng-container *ngIf="!chartsLoading">
            <app-event-log 
                *ngIf="badgeEventsHistory"
                [badgeEvents]="badgeEvents.asObservable()" 
                [badgeEventsHistory]="badgeEventsHistory"
                [rowEventTypes]="rowEventTypes"
                [nameLookUp]="eventNameLookUp()"
                [minHeight]="'200px'"
                class="event-log flexColumn"
            ></app-event-log>
            <app-geofence-time-utilization-chart 
                id="person-detail-time-utilization-chart"
                class="donut-chart"
                [peopleMap]="personMap" 
                [chartType]="peopleOverviewChart.TimeUtilizationChart"
                [styleOptions]="chartStyleOptions"
            ></app-geofence-time-utilization-chart>
            <app-geofence-time-utilization-chart 
                id="person-detail-time-by-area-chart"
                class="donut-chart" 
                [peopleMap]="personMap"
                [chartType]="peopleOverviewChart.HrsWorkedByAreaChart"
                [styleOptions]="chartStyleOptions"
            ></app-geofence-time-utilization-chart>
            <app-location-details
                [map]="map"
                [person]='person'>
            </app-location-details>
        </ng-container>
    </div>
</div>
