import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { css } from 'weavix-shared/utils/css';
import { LoaderTheme } from './loader.model';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: true,
    imports: [
        NgxSkeletonLoaderModule,
    ],
})
export class LoaderComponent implements OnInit, OnChanges {

    private defaultTheme: LoaderTheme = {
        'margin': '0',
        'border-radius': '5px',
        'background-color': css.colors.WHITE_30P,
        width: '100%',
        light: true,
    };

    @Input() theme: LoaderTheme = this.defaultTheme;
    @Input() count: number = 1;

    animation: string;

    constructor() { }

    ngOnInit(): void {
        this.animation = this.theme.light ? 'progress' : 'progress-dark';
    }

    ngOnChanges() {
        if (this.theme) {
            this.theme = { ...this.defaultTheme, ...this.theme };
        }
    }
}
