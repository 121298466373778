import { countBy, max, mean, min, sum } from 'lodash';

export class MetricAggregator<T> {
    private dataPoints: T[] = [];

    /**
     * Adds data point to the collection to aggregate.
     */
    accumulate(dataPoint: T): void {
        this.dataPoints.push(dataPoint);
    }

    minOf(property: keyof T): number | undefined {
        if (this.dataPoints.some(x => typeof x[property] !== 'number')) return NaN;
        return min(this.dataPoints.map(x => x[property] as number));
    }

    maxOf(property: keyof T): number | undefined {
        if (this.dataPoints.some(x => typeof x[property] !== 'number')) return NaN;
        return max(this.dataPoints.map(x => x[property] as number));
    }

    sumOf(property: keyof T): number {
        if (this.dataPoints.some(x => typeof x[property] !== 'number')) return NaN;
        return sum(this.dataPoints.map(x => x[property] as number));
    }

    avgOf(property: keyof T): number {
        if (this.dataPoints.some(x => typeof x[property] !== 'number')) return NaN;
        return mean(this.dataPoints.map(x => x[property] as number));
    }

    /**
     * Counts the unique values of a property in the data points.
     * If a matching value is provided, it will return the count of that value.
     */
    countOf<K extends keyof T>(property: K, matchingValue?: T[K]): number | { [key: string]: number } {
        if (matchingValue) return this.dataPoints.filter(x => x[property] === matchingValue).length;
        return countBy(this.dataPoints, property);
    }

    /**
     * Clears the data points
     */
    clear(): void {
        this.dataPoints = [];
    }

    get dataPointCount(): number {
        return this.dataPoints.length;
    }
}
