import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { IconComponent } from 'components/icon/icon.component';
import { AppIcon } from 'weavix-shared/models/icon.model';

export const BADGE_TYPES = [
    /** Green. */
    'ok',
    /** Blue. */
    'info',
    /** Yellow. */
    'warning',
    /** Purple. */
    'note',
] as const;
export type BadgeType = typeof BADGE_TYPES[number];

/**
 * Visual indicators or icons used to convey status or important information to users.
 * From the weavix Design System 1.0.
 *
 * If you're looking for an interactive alert-like component, use the `app-alert` component instead.
 *
 * @example
 * Wrap any content in the badge component to display an indicator.
 * ```html
 * <app-badge type="info">
 *   {{ message }}
 * </app-badge>
 * ```
 * Styling is applied directly to the badge,
 * so it can be changed with normal CSS or classes.
 * ```html
 * <app-badge style="border-width: 2px;">
 *   {{ message }}
 * </app-badge>
 * ```
 */
@Component({
    selector: 'app-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,

        IconComponent,
    ],
    host: {
        'role': 'status',
        '[class]': '"badge badge-" + type()',
        '[class.badge-has-icon]': '!!icon()',
    },
})
export class BadgeComponent {
    /**
     * The theme of the badge.
     * Defaults to `note`.
     */
    readonly type = input<BadgeType>('note');
    /**
     * The icon to display in the badge.
     * Defaults to `null`.
     */
    readonly icon = input<AppIcon | null>(null);
}
