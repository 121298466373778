import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from 'components/icon/icon.component';
import { AppIcon } from 'weavix-shared/models/icon.model';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

export interface BreadcrumbEntry {
    icon?: AppIcon; // Icon that appears before the text
    textKey?: string; // Text Key to translate and display
    text?: string; // Already translated text to display
    active: boolean; // inactive breadcrumbs will display with slight opacity
    onClickAction?: () => void;
}

@AutoUnsubscribe()
@Component({
    selector: 'app-breadcrumb-header',
    templateUrl: './breadcrumb-header.component.html',
    styleUrls: ['./breadcrumb-header.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,

        IconComponent,
    ],
})
export class BreadcrumbHeaderComponent {
    @Input() breadcrumbs: BreadcrumbEntry[];
}
