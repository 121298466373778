import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { GridsterModule } from '@hyperviewhq/angular2gridster';

/* Third Party */
import { TranslateModule } from '@ngx-translate/core';
import { NouisliderModule } from 'ng2-nouislider';

/* Components */
import { ActionDropdownComponent } from './action-dropdown/action-dropdown.component';
import { AvatarEditorComponent } from './avatar/avatar-editor/avatar-editor.component';
import { AvatarPersonClusterComponent } from './avatar/avatar-person-cluster/avatar-person-cluster.component';
import { AvatarPersonComponent } from './avatar/avatar-person/avatar-person.component';
import { AvatarComponent } from './avatar/avatar.component';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { ButtonListComponent } from './button-list/button-list.component';
import { CalloutComponent } from './callout/callout.component';
import { AccountCardComponent } from './card-select/account-card/account-card.component';
import { CardSelectComponent } from './card-select/card-select.component';
import { CompanyCardComponent } from './card-select/company-card/company-card.component';
import { EmailInviteCardComponent } from './card-select/email-invite-card/email-invite-card.component';
import { FacilityCardComponent } from './card-select/facility-card/facility-card.component';
import { ItemCardComponent } from './card-select/item-card/item-card.component';
import { PeopleCardComponent } from './card-select/people-card/people-card.component';
import { ChangeCompanyModalComponent } from './change-company-modal/change-company-modal.component';
import { ChartLegendComponent } from './chart-legend/chart-legend.component';
import { ChartTitleComponent } from './chart/chart-title/chart-title.component';
import { ChartComponent } from './chart/chart.component';
import { GeofenceTimeUtilizationChartComponent } from './chart/geofence-charts/geofence-time-utilization-chart/geofence-time-utilization-chart.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ChipListComponent } from './chip-list/chip-list.component';
import { ChipComponent } from './chip-list/chip/chip.component';
import { ClockComponent } from './clock/clock.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { DashboardVariableComponent } from './dashboard-variable/dashboard-variable.component';
import { DashboardFilterComponent } from './dashboard/dashboard-filter/dashboard-filter.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DataFilterComponent } from './data-filter/data-filter.component';
import { FilterDropdownComponent } from './data-filter/filter-dropdown/filter-dropdown.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DateRangePickerHeaderNoArrowComponent } from './date-range-picker/date-range-picker-header/date-range-picker-header-no-arrow.component';
import { DateRangePickerHeaderComponent } from './date-range-picker/date-range-picker-header/date-range-picker-header.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DayTimePickerComponent } from './day-time-picker/day-time-picker.component';
import { DayTimeRangePickerComponent } from './day-time-range-picker/day-time-range-picker.component';
import { DeviceSettingsComponent } from './device-settings/device-settings.component';
import { EmailInputComponent } from './email-input/email-input.component';
import { EntityListComponent } from './entity-list/entity-list.component';
import { FacilityDropdownComponent } from './facility-dropdown/facility-dropdown.component';
import { FacilitySelectComponent } from './facility-select/facility-select.component';
import { FolderTreeSelectComponent } from './folder-tree-select/folder-tree-select.component';
import { FormButtonsComponent } from './form-buttons/form-buttons.component';
import { GasReadingIconDisplayComponent } from './gas-reading-icon-display/gas-reading-icon-display.component';
import { GasSensorReadingsDisplayComponent } from './gas-sensor-readings-display/gas-sensor-readings-display.component';
import { HideWindowControlComponent } from './hide-window-control/hide-window-control.component';
import { IconPickerComponent } from './icon-picker/icon-picker.component';
import { IconWithTextComponent } from './icon-with-text/icon-with-text.component';
import { IconComponent } from './icon/icon.component';
import { ImageFeedComponent } from './image-feed/image-feed.component';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { InputListComponent } from './input-list/input-list.component';
import { LoaderComponent } from './loader/loader.component';
import { LoadingComponent } from './loading/loading.component';
import { LogoSpinnerComponent } from './logo-spinner/logo-spinner.component';
import { ModalComponent } from './modal/modal.component';
import { NetworkIndicatorComponent } from './network-indicator/network-indicator.component';
import { NoAccessComponent } from './no-access/no-access.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PersonListComponent } from './person-select/person-list/person-list.component';
import { PersonSelectComponent } from './person-select/person-select.component';
import { PinInputComponent } from './pin-input/pin-input.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { PttModalComponent } from './ptt-modal/ptt-modal.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { RelativeDatePickerComponent } from './relative-date-picker/relative-date-picker.component';
import { FolderEditorModalComponent } from './table/folder-editor-modal/folder-editor-modal.component';
import { ParentEditFormComponent } from './table/parent-edit-form/parent-edit-form.component';
import { TableFilterRowComponent } from './table/table-filter-row/table-filter-row.component';
import { TableFilterComponent } from './table/table-filter/table-filter.component';
import { TableHeaderComponent } from './table/table-header/table-header.component';
import { TableRowComponent } from './table/table-row/table-row.component';
import { TableComponent } from './table/table.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TreeExpansionMenuComponent } from './tree-expansion-menu/tree-expansion-menu.component';
import { UniversalDropdownComponent } from './universal-dropdown/universal-dropdown.component';
import { UploadButtonComponent } from './upload-button/upload-button.component';
import { VolumeBarComponent } from './volume-bar/volume-bar.component';
import { WidgetGridComponent } from './widget-grid/widget-grid.component';
import { ChartWidgetComponent } from './widgets/chart-widget/chart-widget.component';
import { CountComponent } from './widgets/count/count.component';
import { RichTextWidgetComponent } from './widgets/rich-text-widget/rich-text-widget.component';
import { TableWidgetComponent } from './widgets/table-widget/table-widget.component';
import { WidgetContainerComponent } from './widgets/widget-container/widget-container.component';

/* Directives */
import { DragAndDropDirective } from './dnd.directive';
import { Ng2TelInput } from './ng2-tel-input/ng2-tel-input.directive';

/* Pipes */
import { WeavixSharedModule } from 'weavix-shared/weavix-shared.module';
import { AvatarSingleClusterComponent } from './avatar/avatar-single-cluster/avatar-single-cluster.component';
import { AddParticipantCardComponent } from './card-select/add-participant-card/add-participant-card.component';
import { ChannelInfoPersonCardComponent } from './card-select/channel-info-person-card/channel-info-person-card.component';
import { ChannelPersonCardComponent } from './card-select/channel-person-card/channel-person-card.component';
import { ParticipantCardComponent } from './card-select/participant-card/participant-card.component';
import { ChannelMessageAttachmentComponent } from './channel/channel-message-attachment/channel-message-attachment.component';
import { ChannelMessageContextMenuComponent } from './channel/channel-message-context-menu/channel-message-context-menu.component';
import { ChannelMessageLinkPreviewComponent } from './channel/channel-message-link-preview/channel-message-link-preview.component';
import { ChannelComponent } from './channel/channel.component';
import { MediaCarouselComponent } from './channel/media-carousel/media-carousel.component';
import { ReadersListComponent } from './channel/readers-list/readers-list.component';
import { ContainerWithLabelComponent } from './container-with-label/container-with-label.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { FilterHeaderComponent } from './filter-header/filter-header.component';
import { InputInlineEditComponent } from './input-inline-edit/input-inline-edit.component';
import { InputComponent } from './input/input.component';
import { PersonSelectSearchChipComponent } from './person-select/person-select-search-chip/person-select-search-chip.component';
import { PopoverComponent } from './popover/popover.component';
import { ReportComponent } from './report/report.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { TabBarComponent } from './tab-bar/tab-bar.component';
import { TabComponent } from './tab/tab.component';
import { TimePickerDropdownComponent } from './time-picker-dropdown/time-picker-dropdown.component';
import { UserAddMenuComponent } from './user-add-remove/user-add-menu/user-add-menu.component';
import { UserAddRemoveComponent } from './user-add-remove/user-add-remove.component';
import { WeavixBugSvgComponent } from './weavix-bug-svg/weavix-bug-svg.component';
import { HeaderBarComponent } from './table/header-bar/header-bar.component';
import { ButtonDropdownComponent } from './button-dropdown/button-dropdown.component';
import { BadgeComponent } from './badge/badge.component';
import { SimpleConditionComponent } from './person-matcher/simple-person-condition/simple-condition.component';
import { StandardMatcherComponent } from './person-matcher/standard-matcher/standard-matcher.component';
import { PersonMatcherComponent } from './person-matcher/person-matcher.component';
import { ComplexConditionComponent } from './person-matcher/complex-person-condition/complex-condition.component';
import { TemplatedTooltipComponent } from './templated-tooltip/templated-tooltip.component';
import { TemplatedTooltipDirective } from './templated-tooltip/templated-tooltip.directive';
import { ErrorsBannerComponent } from './errors-banner/errors-banner.component';
import { PeoplePreviewComponent } from './people-preview/people-preview.component';
import { PeopleListComponent } from './people-list/people-list.component';
import { PeoplePreviewModalComponent } from './people-preview/people-preview-modal/people-preview-modal.component';
import { TabHeaderComponent } from './tab-header/tab-header.component';
import { BreadcrumbHeaderComponent } from './breadcrumb-header/breadcrumb-header.component';
import { CustomOverlayContainer } from 'weavix-shared/custom-overlay-container';
import { CompanyTypeaheadComponent } from './company-typeahead/company-typeahead.component';
import { SiteChipListComponent } from './site-chip-list/site-chip-list.component';
import { NameExistsComponent } from './modal/name-exists/name-exists.component';
import { WaltDetailComponent } from './detail-views/walt-detail/walt-detail.component';
import { BadgeEventDetailComponent } from './detail-views/badge-event-detail/badge-event-detail.component';
import { GasMonitorReadingsComponent } from './detail-views/gas-monitor-readings/gas-monitor-readings.component';
import { GeofenceDetailComponent } from './detail-views/geofence-detail/geofence-detail.component';
import { ItemDetailComponent } from './detail-views/item-detail/item-detail.component';
import { LocationDetailsComponent } from './detail-views/location-details/location-details.component';
import { PersonDetailComponent } from './detail-views/person-detail/person-detail.component';
import { NetworkCoverageDetailComponent } from './detail-views/network-coverage/network-coverage-detail.component';

@NgModule({
    declarations: [
        AccountCardComponent,
        ActionDropdownComponent,
        ButtonDropdownComponent,
        CardSelectComponent,
        ClockComponent,
        CompanyCardComponent,
        ContainerWithLabelComponent,
        CopyrightComponent,
        DataFilterComponent,
        DatePickerComponent,
        DeviceSettingsComponent,
        EmailInputComponent,
        EmailInviteCardComponent,
        FacilityCardComponent,
        FacilityDropdownComponent,
        FacilitySelectComponent,
        FilterDropdownComponent,
        GasReadingIconDisplayComponent,
        GasSensorReadingsDisplayComponent,
        HideWindowControlComponent,
        IconPickerComponent,
        IconWithTextComponent,
        ImageFeedComponent,
        ImagePreviewComponent,
        InputInlineEditComponent,
        ItemCardComponent,
        NetworkIndicatorComponent,
        NoAccessComponent,
        PeopleCardComponent,
        PersonListComponent,
        PersonSelectComponent,
        PersonListComponent,
        PersonSelectSearchChipComponent,
        PopoverComponent,
        ProgressBarComponent,
        PttModalComponent,
        RangeSliderComponent,
        SnackBarComponent,
        HeaderBarComponent,
        TreeExpansionMenuComponent,
        TreeExpansionMenuComponent,
        UniversalDropdownComponent,
        VolumeBarComponent,
        UploadButtonComponent,
        ChartTitleComponent,
        TimePickerComponent,
        TimePickerDropdownComponent,
        InputListComponent,
        DashboardVariableComponent,
        RelativeDatePickerComponent,
        WidgetGridComponent,
        WidgetContainerComponent,
        CountComponent,
        RichTextWidgetComponent,
        TableWidgetComponent,
        DashboardComponent,
        DashboardFilterComponent,
        ChartWidgetComponent,
        ChangeCompanyModalComponent,
        QrCodeComponent,
        UserAddMenuComponent,
        UserAddRemoveComponent,
        WeavixBugSvgComponent,
        TabHeaderComponent,
        AddParticipantCardComponent,
        ChannelInfoPersonCardComponent,
        ChannelPersonCardComponent,
        ParticipantCardComponent,
        ChannelMessageAttachmentComponent,
        ChannelMessageContextMenuComponent,
        ChannelMessageLinkPreviewComponent,
        MediaCarouselComponent,
        ReadersListComponent,
        ChannelComponent,
        PinInputComponent,
    ],
    imports: [
        CommonModule,
        ClipboardModule,
        DragDropModule,
        FormsModule,
        GridsterModule.forRoot(),
        HttpClientModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatTooltipModule,
        NouisliderModule,
        ReactiveFormsModule,
        RouterModule,
        ScrollingModule,
        TranslateModule,
        MatMenuModule,
        WeavixSharedModule,
        TemplatedTooltipComponent,
        TemplatedTooltipDirective,
        InputComponent,
        TooltipComponent,
        Ng2TelInput,
        DropdownComponent,
        LoadingComponent,
        LogoSpinnerComponent,
        CheckboxComponent,
        ChipComponent,
        ChipListComponent,
        IconComponent,
        ErrorsBannerComponent,
        ModalComponent,
        NameExistsComponent,
        TableComponent,
        TableRowComponent,
        TableHeaderComponent,
        TableFilterComponent,
        TableFilterRowComponent,
        AvatarPersonComponent,
        AvatarComponent,
        AvatarEditorComponent,
        AvatarPersonClusterComponent,
        AvatarSingleClusterComponent,
        ButtonGroupComponent,
        DateRangePickerComponent,
        DateRangePickerHeaderComponent,
        DateRangePickerHeaderNoArrowComponent,
        DayTimePickerComponent,
        DayTimeRangePickerComponent,
        FilterHeaderComponent,
        FolderEditorModalComponent,
        FormButtonsComponent,
        FolderTreeSelectComponent,
        ParentEditFormComponent,
        ColorPickerComponent,
        BadgeComponent,
        CompanyTypeaheadComponent,
        TabComponent,
        TabBarComponent,
        NotificationsComponent,
        ReportComponent,
        DragAndDropDirective,
        SiteChipListComponent,
        ChartComponent,
        LoaderComponent,
        GeofenceTimeUtilizationChartComponent,
        ChartLegendComponent,
        ImageUploadComponent,
        EntityListComponent,
        ButtonListComponent,
        CalloutComponent,
        BadgeEventDetailComponent,
        GasMonitorReadingsComponent,
        GeofenceDetailComponent,
        ItemDetailComponent,
        LocationDetailsComponent,
        NetworkCoverageDetailComponent,
        PersonDetailComponent,
        WaltDetailComponent,
        SimpleConditionComponent,
        StandardMatcherComponent,
        ComplexConditionComponent,
        PersonMatcherComponent,
        PeopleListComponent,
        PeoplePreviewComponent,
        PeoplePreviewModalComponent,
        BreadcrumbHeaderComponent,
    ],
    exports: [
        AccountCardComponent,
        ActionDropdownComponent,
        AvatarComponent,
        AvatarPersonClusterComponent,
        AvatarPersonComponent,
        AvatarSingleClusterComponent,
        BadgeComponent,
        ButtonDropdownComponent,
        ButtonGroupComponent,
        ButtonListComponent,
        CalloutComponent,
        CardSelectComponent,
        ChartComponent,
        ChartLegendComponent,
        CheckboxComponent,
        ChipComponent,
        ChipListComponent,
        ClockComponent,
        ColorPickerComponent,
        CompanyCardComponent,
        ContainerWithLabelComponent,
        CopyrightComponent,
        DataFilterComponent,
        DatePickerComponent,
        DateRangePickerComponent,
        DeviceSettingsComponent,
        DragAndDropDirective,
        DragDropModule,
        EmailInputComponent,
        FacilityDropdownComponent,
        FacilitySelectComponent,
        FilterDropdownComponent,
        FolderEditorModalComponent,
        FolderTreeSelectComponent,
        FormButtonsComponent,
        FormsModule,
        GasReadingIconDisplayComponent,
        GasSensorReadingsDisplayComponent,
        GeofenceTimeUtilizationChartComponent,
        HideWindowControlComponent,
        IconComponent,
        IconPickerComponent,
        IconWithTextComponent,
        ImageFeedComponent,
        ImagePreviewComponent,
        InputInlineEditComponent,
        ItemCardComponent,
        LoaderComponent,
        LoadingComponent,
        LogoSpinnerComponent,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatMenuModule,
        ModalComponent,
        NameExistsComponent,
        NetworkIndicatorComponent,
        NoAccessComponent,
        NotificationsComponent,
        OverlayModule,
        ParentEditFormComponent,
        PersonSelectComponent,
        PopoverComponent,
        ProgressBarComponent,
        PttModalComponent,
        ReportComponent,
        ReactiveFormsModule,
        RangeSliderComponent,
        ScrollingModule,
        SnackBarComponent,
        TableComponent,
        TooltipComponent,
        EntityListComponent,
        TableFilterComponent,
        TableFilterRowComponent,
        TableHeaderComponent,
        TableRowComponent,
        HeaderBarComponent,
        TranslateModule,
        TreeExpansionMenuComponent,
        UniversalDropdownComponent,
        VolumeBarComponent,
        UploadButtonComponent,
        ChartTitleComponent,
        TimePickerComponent,
        TimePickerDropdownComponent,
        InputListComponent,
        DashboardVariableComponent,
        RelativeDatePickerComponent,
        WidgetGridComponent,
        DashboardComponent,
        DashboardFilterComponent,
        ChartWidgetComponent,
        AvatarEditorComponent,
        ChangeCompanyModalComponent,
        QrCodeComponent,
        UserAddRemoveComponent,
        WeavixBugSvgComponent,
        WeavixSharedModule,
        InputComponent,
        DropdownComponent,
        TabComponent,
        TabBarComponent,
        TabHeaderComponent,
        PersonSelectSearchChipComponent,
        FilterHeaderComponent,
        AddParticipantCardComponent,
        ChannelInfoPersonCardComponent,
        ChannelPersonCardComponent,
        ParticipantCardComponent,
        ChannelMessageAttachmentComponent,
        ChannelMessageContextMenuComponent,
        ChannelMessageLinkPreviewComponent,
        MediaCarouselComponent,
        ReadersListComponent,
        ChannelComponent,
        PinInputComponent,
        Ng2TelInput,
        PersonMatcherComponent,
        StandardMatcherComponent,
        TemplatedTooltipDirective,
        ErrorsBannerComponent,
        PeopleListComponent,
        PeoplePreviewComponent,
        PeoplePreviewModalComponent,
        ImageUploadComponent,
        BreadcrumbHeaderComponent,
        CompanyTypeaheadComponent,
        SiteChipListComponent,
        BadgeEventDetailComponent,
        GasMonitorReadingsComponent,
        GeofenceDetailComponent,
        ItemDetailComponent,
        LocationDetailsComponent,
        NetworkCoverageDetailComponent,
        PersonDetailComponent,
        WaltDetailComponent,
    ],
    providers: [
        DatePipe,
        { provide: OverlayContainer, useExisting: CustomOverlayContainer },
    ],
})
export class ComponentsModule { }
