@if (!loading) {
    <div class="container">
        @for (control of formArray.controls; track control; let i = $index) {
            <div class="condition">
                <app-simple-condition
                    [formGroup]="control"
                    [canAdd]="i === formArray.controls.length - 1 && !!unusedCondition"
                    [canRemove]="!defaultEmpty || formArray.controls.length > 1"
                    (remove)="remove(i)"
                    (add)="add()"
                />
            </div>
        } @empty {
            <div class="condition">
                <app-simple-condition
                    [everyone]="true"
                    [everyoneLabel]="everyoneLabel"
                    [canAdd]="true"
                    (add)="add()"
                />
            </div>
        }
    </div>
}
