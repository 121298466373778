import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { retryCancel } from '@weavix/utils/src/retry-cancel';
import { SyncHttp, SyncMqtt, SyncStorage } from './sync.model';
import { SyncModule } from './sync.module';
import { PriorityQueue } from './priority-queue';
import { PermissionAction } from '@weavix/models/src/permission/permissions.model';
import { i18nLocale } from '@weavix/models/src/translate/translate';
import { TelemetryEvent } from '@weavix/models/src/telemetry';
import { AccountDisclaimers } from '@weavix/models/src/account/account';

type AccountEvent = { id: string, removed?: boolean };
export interface UserAccount {
    id: string;
    accounts: AccountInfo[];
    company: { id: string };
    bleIds?: number[];
    phone?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    locale?: i18nLocale;
    dialect?: string;
    enabledActions?: PermissionAction[];

    /**
     * anything on or before these dates have been deleted by data retention
     */
    archived?: {
        /** mass alerts */
        alerts?: Date;
        /** events and badge updates */
        events?: Date;
    };
}

export interface AccountInfo {
    id: string;
    companyId?: string;
    companyIds?: string[];
    enabledActions?: string[];
    allCompanies?: boolean;
    image: string;
    name: string;
    features: Array<{ key: string, value: number }>;
    master?: boolean;
    color?: string;
    disclaimers?: AccountDisclaimers;

    /**
     * anything on or before these dates have been deleted by data retention
     */
    archived?: {
        /** mass alerts */
        alerts?: Date;
        /** events and badge updates */
        events?: Date;
    };
}

export type SyncDestroyer = () => void;
export type SyncTrigger = (user: UserAccount, cache?: boolean) => SyncDestroyer | Promise<SyncDestroyer>;

interface SyncMetrics {
    records: number;
    bytes: number;
}

interface SyncStoreLoading {
    [storeName: string]: BehaviorSubject<number> | undefined;
}

type SyncProperties = { id: '0', version: string, userId?: string };

export class SyncService {
    static triggers: SyncTrigger[] = [];
    static destroyers: Array<SyncDestroyer | Promise<SyncDestroyer>> = [];
    static instance?: SyncService;
    static user$ = new BehaviorSubject<UserAccount | null>(null);
    static version = 'v2'; // update this to force re-sync on client update

    // Triggers are triggered when the user is set or changed, destroyed on logout or user is changed
    static addTrigger(trigger: SyncTrigger) {
        this.triggers.push(trigger);
        const user = SyncService.user$?.value;
        if (user) SyncService.destroyers.push(trigger(user, true));
    }

    private userId?: string;
    private readonly priorityQueue = new PriorityQueue();

    private readonly syncStoreLoading: SyncStoreLoading = {};
    readonly priorityLoading$ = new BehaviorSubject(0);
    readonly lazyLoading$ = new BehaviorSubject(0);

    private accountSubscription: Subscription | undefined;
    private metrics: SyncMetrics = { records: 0, bytes: 0 };

    constructor(
        private readonly http: SyncHttp,
        private readonly mqtt: SyncMqtt,
        private readonly storage: SyncStorage<UserAccount>,
    ) {}

    async initialize(userAccount: UserAccount) {
        this.userId = userAccount.id;
        await this.trigger(userAccount);
    }

    sendTelemetry(name: TelemetryEvent, data: unknown) {
        this.mqtt.sendTelemetry(name, data);
    }

    getCurrentNetworks() {
        return this.mqtt.getCurrentNetworks?.() ?? {};
    }

    async loggedIn() {
        this.metrics = { records: 0, bytes: 0 };

        const properties = await this.getSyncProperties();
        this.userId = properties?.version === SyncService.version ? properties.userId : '-1';
        const user = this.userId !== '-1' ? await this.storage.get(this.userId!) : null;
        if (user) await this.trigger(user, true);
        const wait = this.subscribeUserAccounts();
        if (!user) {
            await wait;
            return false;
        }
        return true;
    }

    private async subscribeUserAccounts() {
        const refreshAccount = async () => {
            const user = await this.get<UserAccount>('/core/me/user');
            this.userId = user.id;
            await this.storage.add(user);
            await this.setSyncProperties(user.id);
            await this.trigger(user);
        };
        const refreshSubscription = async () => {
            const sub = this.subscribe<AccountEvent>(`user/${this.userId}/account-added`);
            this.accountSubscription = (await sub).subscribe({
                next: () => {
                    void refreshAccount();
                },
                error: () => {
                    void this.subscribeUserAccounts();
                },
            });
        };
        const userPromise = refreshAccount();
        if (this.userId === '-1') await userPromise;
        void refreshSubscription();
        await userPromise;
    }

    private async getSyncProperties() {
        return await this.storage.get('0') as unknown as SyncProperties | null | undefined;
    }

    private async setSyncProperties(userId: string) {
        await this.storage.add({ id: '0', userId, version: SyncService.version } satisfies SyncProperties as unknown as UserAccount);
    }

    reportMetric(records: number, bytes: number) {
        this.metrics.records += records;
        this.metrics.bytes += bytes;
    }

    async waitForModules() {
        await new Promise<void>(resolve => {
            this.priorityLoading$.subscribe(val => {
                if (val <= 0) resolve();
            });
        });
        await new Promise<void>(resolve => {
            this.lazyLoading$.subscribe(val => {
                if (val <= 0) resolve();
            });
        });
        return this.metrics;
    }

    async loggedOut(reset = false) {
        this.accountSubscription?.unsubscribe();
        if (reset) {
            const userId = (await this.getSyncProperties())?.userId;
            if (userId) await this.storage.remove(userId);
            await this.storage.remove('0');
        }
        this.userId = undefined;
        await this.trigger(null);

        await Promise.all(SyncModule.modules.map(async module => {
            await module.close(reset);
        }));
    }

    async flush() {
        await Promise.all(SyncModule.modules.map(async module => {
            await module.flush();
        }));
    }

    private async trigger(user: UserAccount | null, cache = false) {
        try { (await Promise.all(SyncService.destroyers)).forEach(x => x()); } catch (e) { console.error(e); }
        SyncService.destroyers = user ? SyncService.triggers.map(x => x(user, cache)) : [];
        SyncService.user$.next(user);
    }

    /**
     * Returns a promise that resolves when subscribed to the MQTT topic.
     * The resolved value is an observable that emits the messages received on the topic.
     *
     * NOTE: If cancelled, the returned promise will never resolve!
     */
    subscribe<T>(topic: string, cancelled: () => boolean = () => !this.userId, lazy = false, simulcast = false) {
        const fn = () => retryCancel(async () => {
            const result = simulcast ? this.mqtt.subscribeMultiple<T>(topic) : this.mqtt.subscribe<T>(topic);
            await result.subscribed;
            return result;
        }, cancelled);
        return lazy ? this.priorityQueue.queue(fn) : this.priorityQueue.run(fn);
    }

    /**
     * Makes an HTTP GET with retries and priority queuing (`lazy`).
     *
     * NOTE: If cancelled, the returned promise will never resolve!
     */
    async get<T>(path: string, params?: unknown, cancelled: () => boolean = () => !this.userId, lazy = false) {
        console.log(`GET ${path} ${SyncService.user$.value?.id}`);
        const fn = () => retryCancel(() => this.http.get<T>(path, params), cancelled);
        return lazy ? this.priorityQueue.queue(fn) : this.priorityQueue.run(fn);
    }

    /**
     * Makes an HTTP POST with retries and priority queuing (`lazy`).
     *
     * NOTE: If cancelled, the returned promise will never resolve!
     */
    async post<T>(path: string, body?: unknown, params?: unknown, cancelled: () => boolean = () => !this.userId, lazy = false) {
        console.log(`POST ${path} ${SyncService.user$.value?.id}`);
        const fn = () => retryCancel(() => this.http.post<T>(path, body, params), cancelled);
        return lazy ? this.priorityQueue.queue(fn) : this.priorityQueue.run(fn);
    }

    changeLoadingCount(change: number, storeName: string): void {
        const loading$ = this.syncStoreLoading[storeName];
        if (!loading$) {
            this.syncStoreLoading[storeName] = new BehaviorSubject<number>(Math.max(0, change));
        } else {
            loading$.next(loading$.value + change);
        }
    }

    getLoadingCount$(storeName: string): Observable<number> {
        return this.syncStoreLoading[storeName]?.asObservable() ?? of();
    }
}
