import { Component, OnInit, computed, input, output, signal } from '@angular/core';
import { ThemeService } from 'weavix-shared/services/themeService';
import { DateRange } from 'weavix-shared/models/dvr.model';
import { MapFilterResult } from 'weavix-shared/models/weavix-map.model';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from 'components/loading/loading.component';
import { FilterHeaderComponent } from 'components/filter-header/filter-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { FilterRowDisplayComponent } from './filter-row-display/filter-row-display.component';

@AutoUnsubscribe()
@Component({
    selector: 'app-weavix-map-filter',
    templateUrl: './weavix-map-filter.component.html',
    styleUrls: ['./weavix-map-filter.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,

        LoadingComponent,
        FilterHeaderComponent,
        FilterRowDisplayComponent,
    ],
})
export class WeavixMapFilterComponent implements OnInit {
    readonly title = input<string>('generics.filters');
    readonly clearText = input<string>('map.controls.clear-filters');
    readonly filterResults = input<{ [key: string]: MapFilterResult }>({});
    readonly dateRange = input<DateRange>(undefined);
    readonly hideDateRange = input<boolean>(false);
    readonly isDateRangeDisabled = input<boolean>(false);
    readonly multiCategorySelect = input(false);
    readonly ignoreViewSubscriptions = input<boolean>(false);
    readonly dateRangeChanged = output<DateRange>();

    readonly loading = signal(false);
    readonly lightTheme = signal(false);

    readonly filterResultsSorted = computed(() => {
        const results = Utils.sortAlphabetical(Object.values(this.filterResults()), res => res?.name?.toLowerCase());
        // filtered options with no children should always be at the bottom of the list
        results.map((elem, index) => {
            if (elem.children) return;
            results.splice(index, 1);
            results.push(elem);
        });
        return results;
    });

    getSortedChildren(result: MapFilterResult) {
        return Utils.sortAlphabetical(Object.values(result.children ?? {}), res => res.name?.toLocaleLowerCase());
    }

    ngOnInit() {
        this.loading.set(true);
        this.lightTheme.set(ThemeService.getLightTheme());
        this.loading.set(false);
    }

    handleClearFilters() {
        this.clearAllSelections({ children: this.filterResults() } as any);
    }

    deselectCategory(cat: MapFilterResult, ignore?, clearMultiSelections: boolean = false): void {
        if (cat.children) {
            cat.childrenSelected = (cat.multiselect && !clearMultiSelections) && Object.keys(cat?.children ?? {}).some(k => cat?.children[k].selected);
            Object.values(cat.children).forEach(subCat => {
                if (subCat === ignore || !subCat.selected) return;
                if (cat.multiselect !== true || clearMultiSelections) subCat.selected = false;
                subCat.setSelected?.(subCat.selected);
                subCat.hidden = false;
                this.deselectCategory(subCat);
            });
        }
    }

    /*-- clears all selections for single and multi selection--*/
    clearAllSelections(cat: MapFilterResult): void {
        if (cat?.children) {
            cat.childrenSelected = false;
            Object.values(cat.children).forEach(subCat => {
                if (!subCat.selected && !subCat.childrenSelected) return;
                subCat.selected = false;
                subCat.setSelected?.(subCat.selected, true);
                subCat.hidden = false;
                this.clearAllSelections(subCat);
            });
        }
    }

    handleRowClick(parent: MapFilterResult, cat: MapFilterResult, click: boolean, depth: number): void {
        if (!cat) return;

        if (!this.multiCategorySelect()) this.deselectCategory(parent, cat);
        if (depth > 0 && parent.multiselect !== true) this.deselectCategory(parent, cat);
        if (cat.selected || click) this.deselectCategory(cat);
        cat.selected = click ? true : !cat.selected;
        cat.setSelected?.(cat.selected, true, click);
    }

    onDateRangeChanged(value: DateRange) {
        this.dateRangeChanged.emit(value);
    }
}
