import { Component, computed, inject, input, signal } from '@angular/core';
import { PersonSelect } from '@weavix/models/src/person/person-select';
import { PeoplePreviewModalComponent } from './people-preview-modal/people-preview-modal.component';
import { IconComponent } from 'components/icon/icon.component';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

/**
 * Displays a button, which launches a modal with a list of people.
 */
@Component({
    selector: 'app-people-preview',
    templateUrl: './people-preview.component.html',
    styleUrls: ['./people-preview.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,

        IconComponent,
        PeoplePreviewModalComponent,
    ],
})
export class PeoplePreviewComponent {
    private readonly translate = inject(TranslateService);

    readonly data = input<PersonSelect[]>([]);

    readonly openModal = signal(false);
    readonly buttonText = computed(() => this.translate.instant('configuration.person-matcher.view-n-selected-users', { n: this.data().length }));

    showModal(): void {
        this.openModal.set(true);
    }

    modalClosed(): void {
        this.openModal.set(false);
    }
}
