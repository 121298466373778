<div class="container" [formGroup]="everyone() ? everyoneGroup : formGroup()">
    <app-dropdown
        class="key-input"
        formControlName="key"
        [dropdownInput]="everyone() ? everyoneKey : key"
        [placeholder]="'configuration.workflows.step-form.select-condition'"
        [readonly]="everyone()"
        (dropdownOutput)="checkNegate()"
    />
    @if (everyone() || formGroup().get('key').value) {
        <app-dropdown
            [class]="!everyone() && formGroup().get('self').value ? 'negate-input-full' : 'negate-input'"
            errorClass="block"
            [formControl]="negateForm"
            [skipSort]="true"
            [readonly]="everyone()"
            [dropdownInput]="everyone() ? everyoneNegate : (negate[formGroup().get('key').value] ?? [])"
        />
    }
    @if (everyone() || !formGroup().get('self').value) {
        <app-chip-list
            class="value-input"
            formControlName="value"
            [removeSelected]="false"
            [isDisabled]="everyone() || !formGroup().get('key').value"
            [dataSource]="everyone() ? everyoneValue() : (value[formGroup().get('key').value] ?? [])"
        />
    }
    <div class="row-actions flexRow jcFS gap10">
        @if (canRemove() && !everyone()) {
            <button type="button" class="button remove" (click)="remove.emit()">
                <app-icon [faIcon]="'fas fa-trash'" class="icon" />
            </button>
        }
        @if (canAdd()) {
            <button type="button" class="button" (click)="add.emit()"
                matTooltip="{{ ('configuration.person-matcher.' + (grouped() ? 'add-grouped-condition' : 'add-condition')) | translate }}">
                <app-icon [faIcon]="'fas fa-plus-circle'" class="blue-lt icon" />
            </button>
        }
    </div>
</div>
