<div class="preview-wrapper">
    <button class="preview-button" (click)="showModal()" [class.disabled]="!data() || !data().length">
        <div><app-icon faIcon="fas fa-users" /></div>
        <div>{{buttonText()}}</div>
    </button>
</div>

<app-people-preview-modal
    class="pAbs"
    [open]="openModal()"
    [data]="data()"
    (closed)="modalClosed()"
/>
