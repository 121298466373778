@if (loading) {
    <app-loading [loading]="loading" />
} @else {
    <div class="container">
        <div class="tab-bar">
            <div class="tab-left flexRow aiC">
                {{ label | translate }}
            </div>
            <div class="tab-right flexRow aiC gap30">
                <mat-slide-toggle
                    class="toggle blue" 
                    [formControl]="advanced.controls.enabled"
                    labelPosition="before"
                    hideIcon
                >
                    {{ 'configuration.person-matcher.advanced' | translate }}
                </mat-slide-toggle>
                @if (isShowPreview) {
                    <app-people-preview
                        [data]="filteredPeople"
                    />
                }
            </div>
        </div>

        <div class="content">
            @if (!isAdvanced) {
                <app-standard-matcher
                    [formArray]="standard"
                    [includes]="includes"
                    [self]="self"
                    [defaultEmpty]="defaultEmpty"
                    [everyoneLabel]="everyoneLabel"
                />
            } @else {
                <app-complex-condition
                    [formGroup]="advanced"
                    [defaultEmpty]="defaultEmpty"
                    [everyoneLabel]="everyoneLabel"
                />
            }
        </div>
    </div>
}
