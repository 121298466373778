import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DEFAULT_KEYS, SimplePersonCondition, StandardConditions } from '@weavix/models/src/person/person-matcher';
import { DropdownItem } from 'components/dropdown/dropdown.model';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';
import { ComplexConditionForm, PersonMatcherService, SimpleConditionForm } from './person-matcher.service';
import { Subscription } from 'rxjs';
import { isEqual } from 'lodash';
import { PersonSelect } from '@weavix/models/src/person/person-select';
import { LoadingComponent } from 'components/loading/loading.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { PeoplePreviewComponent } from 'components/people-preview/people-preview.component';
import { ComplexConditionComponent } from './complex-person-condition/complex-condition.component';
import { StandardMatcherComponent } from './standard-matcher/standard-matcher.component';
import { CommonModule } from '@angular/common';

export interface PersonMatcherHelper {
    keys: DropdownItem[];
    negate: { [key: string]: DropdownItem[] };
}

@AutoUnsubscribe()
@Component({
    selector: 'app-person-matcher',
    templateUrl: './person-matcher.component.html',
    styleUrls: ['./person-matcher.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        TranslateModule,

        LoadingComponent,
        PeoplePreviewComponent,
        StandardMatcherComponent,
        ComplexConditionComponent,
    ],
})
export class PersonMatcherComponent implements OnInit, OnDestroy {
    @Input() label = 'configuration.person-matcher.conditional-filter';
    @Input() formGroup: FormGroup;
    @Input() includes = DEFAULT_KEYS;
    @Input() legacy = false;
    @Input() self = false;
    @Input() defaultEmpty = false;
    @Input() isShowPreview = false;
    @Input() filteredPeople: PersonSelect[] = [];
    @Input() everyoneLabel?: string;
    @Output() changed = new EventEmitter<void>();

    advanced: ComplexConditionForm;
    standard: FormArray<SimpleConditionForm>;
    helper: PersonMatcherHelper;

    loading = true;

    subscriptions: Subscription[] = [];

    constructor(
        private personMatcherService: PersonMatcherService,
    ) {}

    get isAdvanced() {
        return this.advanced?.controls?.enabled?.value;
    }

    async ngOnInit() {
        this.advanced = this.formGroup.get('advancedCondition') as FormGroup;
        if (this.legacy) {
            this.standard = new FormArray([]);
            Object.keys(StandardConditions).forEach(key => {
                if (!this.includes.includes(StandardConditions[key].key)) return;

                const formValue = this.formGroup.get(key)?.value;
                if (formValue?.length) {
                    const control: SimpleConditionForm = new FormGroup({
                        key: new FormControl(StandardConditions[key].key),
                        negate: new FormControl(StandardConditions[key].negate),
                        value: new FormControl(formValue),
                        self: new FormControl(false),
                    });
                    this.standard.push(control);
                }
            });
        } else {
            this.standard = this.formGroup.get('standardConditions') as FormArray;
        }
        this.subscriptions = [
            this.advanced.get('enabled').valueChanges.subscribe(value => {
                this.personMatcherService.setAdvanced(this.isAdvanced, this.standard, this.includes, this.self);
            }),
            this.standard.valueChanges.subscribe((value: SimplePersonCondition[]) => {
                if (this.legacy) {
                    Object.keys(StandardConditions).forEach(key => {
                        const conditionalKey = StandardConditions[key].key;
                        if (!this.includes.includes(conditionalKey)) return;

                        const source = value.filter(v => v.key === conditionalKey && v.negate === StandardConditions[key].negate);
                        const values = source?.flatMap(v => v.value) ?? [];
                        this.formGroup.get(key).setValue(values);

                        this.formGroup.markAsDirty();
                    });
                }
                this.personMatcherService.setAdvanced(this.isAdvanced, this.standard, this.includes, this.self);
                this.changed.emit();
            }),
            this.advanced.valueChanges.subscribe(value => {
                this.changed.emit();
            }),
        ];

        try {
            await this.personMatcherService.loadValues();
        } catch (e) {
            console.error('chip loading', e);
        }
        this.personMatcherService.setAdvanced(this.isAdvanced, this.standard, this.includes, this.self);
        this.loading = false;

        // emit after loading to populate
        this.changed.emit();
        const standardValue = this.standard.value;
        const advancedValue = this.advanced.value;
        Utils.safeSubscribe(this, this.advanced.valueChanges).subscribe(() => {
            if (!isEqual(this.advanced.value, advancedValue)) this.advanced.markAsDirty();
            else this.advanced.markAsPristine();
        });
        Utils.safeSubscribe(this, this.standard.valueChanges).subscribe(() => {
            if (!isEqual(this.standard.value, standardValue)) this.standard.markAsDirty();
            else this.standard.markAsPristine();
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(v => v.unsubscribe());
    }
}
