import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingComponent } from 'components/loading/loading.component';
import { PersonSelect } from 'components/person-select/person-select.model';
import { TableComponent } from 'components/table/table.component';
import { TableService } from 'components/table/table.service';
import { Subscription } from 'rxjs';
import { Person } from 'weavix-shared/models/person.model';
import { RowItemType, TableColumn, TableHeaderOptions, TableOptions } from 'weavix-shared/models/table.model';
import { css } from 'weavix-shared/utils/css';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-people-list',
    templateUrl: './people-list.component.html',
    styleUrls: ['./people-list.component.scss'],
    providers: [TableService],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,

        TableComponent,
        LoadingComponent,
    ],
})
export class PeopleListComponent implements OnInit, OnChanges, OnDestroy {
    constructor(
        private readonly tableService: TableService,
    ) { }

    @Input() data: PersonSelect[] = [];
    @Input() isFullView = false;
    @Input() displayLoading: boolean = false; // display a loading spinner on data change
    @Input() loadingTimeout: number = 500; // time in ms to wait before stopping loading spinner
    @Input() titleText: string = 'people-list.title';

    tableOptions: TableOptions;
    tableRows: PersonSelect[];
    isLoading: boolean = false;

    tableFiltersSubscription: Subscription;

    ngOnInit(): void {
        this.tableOptions = this.createTableOptions();
        this.tableService.initFilters();
        this.subscribeToTableFilters();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            this.tableService.clearFilters();
            const currentData = changes.data?.currentValue !== undefined ? changes.data.currentValue as PersonSelect[] : [];
            if (this.displayLoading && !changes.data.isFirstChange()) {
                this.isLoading = true;
                setTimeout(() => {
                    this.isLoading = false;
                    this.tableRows = currentData;
                }, this.loadingTimeout);
            } else {
                this.tableRows = currentData;
            }
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeToTableFilters();
    }

    private subscribeToTableFilters(): void {
        this.unsubscribeToTableFilters();
        this.tableFiltersSubscription = this.tableService.filterUpdate$.subscribe(({ resetFilters }) => {
            if (resetFilters) this.tableService.clearFilters();
            this.tableRows = this.data.filter(p => {
                return this.tableService.checkAndApplyFiltersToRow(p);
            });
        });
    }

    private unsubscribeToTableFilters(): void {
        if (this.tableFiltersSubscription) this.tableFiltersSubscription.unsubscribe();
    }

    private createTableOptions(): TableOptions {
        return {
            title: null,
            keyCol: 'id',
            rowEdits: [],
            backgroundColor: '#2E2E2E',
            columns: this.createColumns(),
            select: { showCheckboxes: false, rowsClickable: false },
            hideTableIntro: true,
            headerOptions: this.createHeaderOptions(),
            noData: {
                messageKey: 'people-list.no-data-message',
                canAdd: false,
            },
            pagination: {
                initPageSize: 30,
                showMoreSize: 20,
                pageUpdated: (shown: number, total: number) => this.tableService.setPaginationInfo({ shown, total }),
            },
            rowCount: true,
        };
    }

    private createHeaderOptions(): TableHeaderOptions {
        const fullViewSearchFields = [
            'company.name',
            (person: PersonSelect) => {
                return person.craftNames?.join(', ') || '';
            },
            (person: PersonSelect) => {
                return person.tagNames?.join(', ') || '';
            },
        ];
        return {
            isPaginationInfoVisible: true,
            search: {
                fields: [
                    'firstName',
                    'lastName',
                    ...(this.isFullView ? fullViewSearchFields : []),
                ],
                icon: 'fas fa-search',
                searchAction: (columnNames: Array<string | ((row) => any)>, value: string) => {
                    this.tableService.searchFilter = { columnNames, value };
                    this.tableService.filterUpdate$.next({ resetFilters: false });
                },
                show: true,
            },
        };
    }

    private createColumns(): TableColumn[] {
        const fullViewColumns = [{
            id: 'col-company',
            title: 'person.company',
            colKey: 'company.name',
            type: RowItemType.text,
            minWidth: 150,
            sort: { sortable: true },
        },
        {
            id: 'col-status',
            title: 'generics.status',
            colKey: 'status',
            type: RowItemType.text,
            minWidth: 150,
            sort: { sortable: true },
        },
        {
            id: 'col-crafts',
            title: 'person.crafts',
            colKey: 'crafts',
            minWidth: 150,
            type: RowItemType.text,
            sort: { sortable: true },
            value: (row: PersonSelect) => row.craftNames?.join(', '),
        },
        {
            id: 'col-tags',
            title: 'person.tags',
            colKey: 'tags',
            minWidth: 150,
            type: RowItemType.text,
            sort: { sortable: true },
            value: (row: PersonSelect) => row.tagNames?.join(', '),
        }];
        return [
            {
                id: 'col-first-name',
                titlePrefixIcon: {
                    faIcon: 'fas fa-user-hard-hat',
                    fontSize: '20px',
                    bgCircle: {
                        bgColor: css.colors.GRAY_DK,
                        diameter: '32px',
                    },
                },
                title: 'generics.firstName',
                colKey: 'firstName',
                type: RowItemType.avatarPerson,
                sort: { sortable: true },
                value: (row: Person) => ({
                    id: row.id,
                    name: row.firstName,
                    avatar: {
                        height: 32,
                        width: 32,
                        hasBorders: false,
                    },
                    avatarFile: row.avatarFile,
                }),
            },
            {
                id: 'col-last-name',
                title: 'generics.lastName',
                colKey: 'lastName',
                type: RowItemType.text,
                sort: { sortable: true },
            },
            ...(this.isFullView ? fullViewColumns : []),
        ];
    }
}
