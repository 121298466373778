import { SyncStorage } from '../sync.model';
import { Subject } from 'rxjs';
import { LazyStorage, withStorage } from './lazy-storage';
import { removeBy } from '@weavix/utils/src/array';
import { NativeStorage } from './native-storage';
import { isNil } from 'lodash';

export type ChangeRecord<T> = {
    partitions?: string[];
    value: T;
    index: string;
    keys?: string[];
    delete?: boolean;
};

export class IndexedStorage<T extends { id: string, partition?: string }> implements SyncStorage<T> {
    private readonly changedSubject$ = new Subject<ChangeRecord<T>>();
    public readonly changed$ = this.changedSubject$.asObservable();

    private readonly partitionChangedSubject$ = new Subject<boolean>();
    public readonly partitionChanged$ = this.partitionChangedSubject$.asObservable();

    get silent() {
        return this.ignoreChanges;
    }

    set silent(value: boolean) {
        this.ignoreChanges = value;
    }

    private ignoreChanges = false;
    private addedPartitions: { [key: string]: boolean } = {};

    // Stores all objects by their indexed value
    private indexIdObject: { [index: string]: LazyStorage<T> } = {};

    // Keeps track of where a given ID is stored and what partitions it is on
    private readonly idIndex: LazyStorage<string>;
    private readonly idPartitions: LazyStorage<string[]>;

    constructor(
        private readonly storage: NativeStorage,
        private readonly indexFn: (value: T) => string = () => 'root',
        private readonly indexPartition: (index: string) => string = val => val,
        private readonly readonly = false,
    ) {
        this.idIndex = new LazyStorage(this.storage, 'id-index', this.readonly);
        this.idPartitions = new LazyStorage(this.storage, 'id-partitions', this.readonly);
    }

    private getObjects(index: string) {
        const partialIndex = this.indexPartition(index);
        if (!this.indexIdObject[partialIndex]) this.indexIdObject[partialIndex] = new LazyStorage<T>(this.storage, partialIndex, this.readonly);
        return this.indexIdObject[partialIndex];
    }

    async dump(partition: string) {
        console.log(`${partition} has in storage ${Object.keys(await this.idIndex.getMap()).length}`);
    }

    async removePartition(partition: string) {
        delete this.addedPartitions[partition];

        this.idPartitions.hold();
        this.idIndex.hold();

        const partitions = await this.idPartitions.getMap();
        const indexes = await this.idIndex.getMap();
        const indexMap: { [key: string]: { [id: string]: T | null | undefined } } = {};

        for (const id in partitions) {
            const partitionList = partitions[id];
            if (!partitionList?.includes(partition)) continue;

            const index = indexes[id];
            if (!index) continue;

            const partialIndex = this.indexPartition(index);

            if (!indexMap[partialIndex]) {
                this.getObjects(index).hold();
                indexMap[partialIndex] = await this.getObjects(index).getMap();
            }

            const partitionIndex = partitionList.indexOf(partition);
            if (partitionIndex >= 0) {
                partitionList.splice(partitionIndex, 1);
            }
            if (!partitionList?.length) {
                delete partitions[id];
                delete indexes[id];
                delete indexMap[partialIndex][id];
            }
        }

        this.idIndex.unhold();
        this.idPartitions.unhold();
        Object.keys(indexMap).forEach(index => this.getObjects(index).unhold());

        this.partitionChangedSubject$.next(true);
    }

    addPartition(partition: string) {
        this.addedPartitions[partition] = true;
        this.partitionChangedSubject$.next(true);
    }

    async clear(partition: string, data: T[]) {
        const listening = this.addedPartitions[partition];
        if (listening) delete this.addedPartitions[partition];

        this.idPartitions.hold();
        this.idIndex.hold();

        const partitions = await this.idPartitions.getMap();
        const indexes = await this.idIndex.getMap();
        const indexMap: { [key: string]: { [id: string]: T | null | undefined } } = {};
        const added: { [key: string]: T } = {};

        for (const value of data) {
            value.partition = partition;
            added[value.id] = value;

            const index = this.indexFn(value);
            const partialIndex = this.indexPartition(index);
            indexes[value.id] = index;
            if (!indexMap[partialIndex]) {
                this.getObjects(index).hold();
                indexMap[partialIndex] = await this.getObjects(index).getMap();
            }
            indexMap[partialIndex][value.id] = value;

            const partitionList = partitions[value.id];
            if (!partitionList) partitions[value.id] = [partition];
            else if (!partitionList.includes(partition)) partitionList.push(partition);
        }

        for (const id in partitions) {
            const partitionList = partitions[id];
            if (added[id] || !partitionList?.includes(partition)) continue;

            const index = indexes[id];
            if (!index) continue;

            const partialIndex = this.indexPartition(index);

            if (!indexMap[partialIndex]) {
                this.getObjects(index).hold();
                indexMap[partialIndex] = await this.getObjects(index).getMap();
            }

            const partitionIndex = partitionList.indexOf(partition);
            if (partitionIndex >= 0) {
                partitionList.splice(partitionIndex, 1);
            }
            if (!partitionList?.length) {
                delete partitions[id];
                delete indexes[id];
                delete indexMap[partialIndex][id];
            }
        }

        this.idIndex.unhold();
        this.idPartitions.unhold();
        Object.keys(indexMap).forEach(index => this.getObjects(index).unhold());

        if (listening) this.addPartition(partition);
    }

    async flush() {
        await Promise.all([
            this.idIndex.flush(),
            this.idPartitions.flush(),
            ...Object.values(this.indexIdObject).map(iio => iio.flush()),
        ]);
    }

    async get(id: string) {
        const index = await this.idIndex.get(id);
        return isNil(index) ? null : await this.getObjects(index).get(id);
    }

    async getAll(ids: string[]) {
        const indexes = await this.idIndex.getMap();
        const indexMap: { [key: string]: { [id: string]: T | null | undefined } } = {};

        const values: T[] = [];
        for (const id of ids) {
            const index = indexes[id];
            if (!index) continue;

            const partialIndex = this.indexPartition(index);
            if (!indexMap[partialIndex]) {
                indexMap[partialIndex] = await this.getObjects(index).getMap();
            }
            const value = indexMap[partialIndex][id];
            if (value) values.push(value);
        }
        return values;
    }

    getIndex() {
        return this.idIndex.getMap();
    }

    getPartitionsByIds() {
        return this.idPartitions.getMap();
    }

    update(id: string, updateFn: (val: T) => T, getKeys: () => string[], partition?: string) {
        return withStorage(this.idIndex, id, index => {
            if (isNil(index)) return;
            const map = this.getObjects(index);
            return withStorage(map, id, record => {
                const updated = updateFn(record!); // Null-forgiving: should exist if index wasn't null.
                this.getObjects(index).set(id, updated);

                if (!this.ignoreChanges && (!partition || this.addedPartitions[partition])) {
                    return withStorage(this.idPartitions, id, partitions => {
                        if (!partitions) partitions = [];
                        const keys = getKeys();
                        this.changedSubject$.next({ partitions, value: updated, index, keys });
                    });
                }
            });
        });
    }

    add(record: T, partition?: string) {
        if (partition) record.partition = partition;
        const index = this.indexFn(record);
        this.getObjects(index).set(record.id, record);
        this.idIndex.set(record.id, index);

        return withStorage(this.idPartitions, record.id, partitions => {
            if (!partitions) partitions = [];
            if (partition && !partitions.includes(partition)) {
                partitions.push(partition);
                this.idPartitions.set(record.id, partitions);
            }
            if (!this.ignoreChanges && (!partition || this.addedPartitions[partition])) {
                this.changedSubject$.next({
                    partitions,
                    value: record,
                    index,
                });
            }
        });
    }

    remove(id: string, partition?: string) {
        return withStorage(this.idIndex, id, index => {
            if (isNil(index)) return;
            const map = this.getObjects(index);
            return withStorage(map, id, record => {
                return withStorage(this.idPartitions, id, partitions => {
                    if (!partitions) partitions = [];
                    if (partition) removeBy(partitions, x => x === partition);
                    if (partitions.length) {
                        this.idPartitions.set(id, partitions);
                    } else {
                        map.remove(id);
                        this.idIndex.remove(id);
                        this.idPartitions.remove(id);
                        if (!this.ignoreChanges && (!partition || this.addedPartitions[partition])) {
                            this.changedSubject$.next({
                                partitions,
                                value: record!, // Null-forgiving: should exist if index wasn't null.
                                delete: true,
                                index,
                            });
                        }
                    }
                });
            });
        });
    }

    async removeMany(fn: (id: string, index: string | null | undefined) => boolean) {
        const index = await this.idIndex.getMap();
        const partitions = await this.idPartitions.getMap();
        for (const key of Object.keys(index)) {
            if (fn(key, index[key])) {
                for (const partition of (partitions[key] ?? [])) {
                    await this.remove(key, partition);
                }
            }
        }
    }
}
