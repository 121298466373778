<div class="building-header">
    @for (breadcrumb of breadcrumbs; track breadcrumb; let i = $index) {
        @if (i !== 0) {
            <i class="fas fa-chevron-right" style="padding-right: 20px; opacity: .5;"></i>
        }

        <div class="header-group cPointer"
            [style]="breadcrumb.active ? 'opacity: 1;' : !!breadcrumb.onClickAction ? 'opacity: .5;' : 'opacity: .5 !important;'"
            [style.cursor]="breadcrumb.onClickAction ? 'pointer' : 'default !important'"
            [style.textDecoration]="breadcrumb.onClickAction ? 'underline' : 'none'"
            (click)="breadcrumb.onClickAction ? breadcrumb.onClickAction() : undefined"
        >
            @if (breadcrumb.icon) {
                <app-icon class="icon" [icon]="breadcrumb.icon" />
            }
            <div class="header-name">{{ breadcrumb.text ? breadcrumb.text : breadcrumb.textKey | translate }}</div>
        </div>
    }
</div>
