import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarComponent } from 'components/avatar/avatar.component';
import { IconComponent } from 'components/icon/icon.component';
import { omit } from 'lodash';
import { EntityList, EntityListItem } from 'weavix-shared/models/entity-list.model';
import { FilterResultType } from 'weavix-shared/models/weavix-map.model';
import { css } from 'weavix-shared/utils/css';
import { FEATURE_ICONS } from 'weavix-shared/utils/feature.icons';
import { Utils } from 'weavix-shared/utils/utils';

@Component({
    selector: 'app-entity-list',
    templateUrl: './entity-list.component.html',
    styleUrls: ['./entity-list.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,

        AvatarComponent,
        IconComponent,
    ],
})
export class EntityListComponent implements OnInit, OnChanges {
    @Input() title: { value: string, translate: boolean };
    @Input() headerColor: string = css.colors.LT_BLUE;
    @Input() includeClose: boolean = false;
    @Input() list: EntityList;
    @Input() maxHeight: number;
    @Input() sortList: boolean = true;
    @Input() itemsClickable: boolean = true;
    @Output() closeOutput = new EventEmitter<void>();
    @Output() itemClick = new EventEmitter<EntityListItem>();

    FilterResultType = FilterResultType;
    featureIcons = FEATURE_ICONS;
    listItems: EntityListItem[] = [];

    constructor() { }

    ngOnChanges() {
        this.updateList();
    }

    ngOnInit(): void {
        this.updateList();
    }

    private updateList(): void {
        const unsortedItems = [];
        Object.keys(this.list ?? {}).forEach(k => {
            const items = this.list[k];
            items.forEach(item => {
                if (k === FilterResultType.Person) unsortedItems.push(omit({ ...item, type: k, name: item.fullName }, 'fullName'));
                else unsortedItems.push({ ...item, type: k });
            });
        });

        this.listItems = this.sortList ? Utils.sortAlphabetical(unsortedItems, (x) => x.name) : unsortedItems;
    }

    handleItemClick(item: EntityListItem): void {
        this.itemClick.emit(item);
    }
}
