<div class="wrapper" [ngClass]="{'light': lightTheme() }">
    <div class="content-wrapper">
        @if (loading()) {
            <app-loading></app-loading>
        } @else {
            <app-filter-header
                [titleKey]="title()"
            ></app-filter-header>

            <div class="scroll-wrapper filter-results">
                <!-- <app-date-range-picker
                        *ngIf="!hideDateRange"
                        [(ngModel)]="dateRange"
                        [disabled]="isDateRangeDisabled"
                        (ngModelChange)="onDateRangeChanged($event)"
                ></app-date-range-picker> -->

                @for (res of filterResultsSorted(); track res) {
                    <ng-container *ngTemplateOutlet="resItem; context: {parent: {children: filterResults()}, res, resIndex: 0}"></ng-container>
                }
            </div>
        }

        <div class="actions">
            <button class="clear-filters" (click)="handleClearFilters()">
                {{ clearText() | translate }}
            </button>
        </div>
    </div>
</div>

<ng-template #resItem let-res="res" let-resIndex="resIndex" let-parent="parent">
    <app-filter-row-display [result]="res" [index]="resIndex" (selectEmit)="handleRowClick(parent, $event, false, resIndex)" (clickEmit)="handleRowClick(parent, $event, true, resIndex)"></app-filter-row-display>

    @if (res?.children && res.selected) {
        @for (child of getSortedChildren(res); track child) {
            <div class="children">
                <ng-container *ngTemplateOutlet="resItem; context: {parent: res, res: child, resIndex: resIndex + (res.depth ? 2 : 1)}"></ng-container>
            </div>
        }
    }
</ng-template>
