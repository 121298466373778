
<div [class]="nested ? 'nested-container container' : 'top-container container'" [formGroup]="formGroup">
    <div class="conditions-container">
        @for (control of conditions.controls; track control; let i = $index) {
            <div class="condition">
                @if (control.get('key')) {
                    <app-simple-condition
                        [formGroup]="control"
                        [canAdd]="i === conditions.controls.length - 1"
                        [grouped]="true"
                        (remove)="removeSimple(i)"
                        (add)="add(i)"
                    />
                } @else {
                    <app-complex-condition
                        class="input-container"
                        [nested]="true"
                        [formGroup]="control"
                        [everyoneLabel]="everyoneLabel"
                        (remove)="removeComplex(i)"
                    />
                }
                @if (i !== conditions.controls.length - 1) {
                    <div [class]="nested ? 'type-container nested-type' : 'type-container root-type'">
                        <button [class]="isAnd ? 'type-button selected' : 'type-button unselected'"
                            (click)="setType('and')">
                            {{ 'rForm.andLogicLabel' | translate }}
                        </button>
                        <button [class]="!isAnd ? 'type-button selected' : 'type-button unselected'"
                            (click)="setType('or')">
                            {{ 'rForm.orLogicLabel' | translate }}
                        </button>
                    </div>
                }
            </div>
        } @empty {
            <div class="condition input-container">
                <app-simple-condition
                    [everyone]="true"
                    [everyoneLabel]="everyoneLabel"
                    [canAdd]="true"
                    (add)="add()"
                />
            </div>
        }
        @if (conditions.controls.length && !nested) {
            <div class="add-container">
                <button (click)="add()" matTooltip="{{ 'configuration.person-matcher.add-top-condition' | translate }}">
                    <app-icon [faIcon]="'fas fa-plus-circle'" class="blue-lt icon"></app-icon>
                </button>
            </div>
        }
    </div>
</div>
