import { map } from 'rxjs';
import { Observable, Subscription } from 'rxjs';
import { SyncEventStorage, SyncStorage } from './sync.model';
import { SyncPartition } from './sync.partition';
import { SyncService, UserAccount } from './sync.service';

export abstract class SyncModule<T extends { id?: string }> {
    static readonly modules: Array<SyncModule<{ id?: string }>> = [];

    private subscription?: Subscription;
    private readonly partitions: { [topic: string]: SyncPartition<T> } = {};

    protected get syncService() {
        return SyncService.instance;
    }

    protected abstract storage: SyncStorage<T>;
    protected abstract eventStorage: SyncEventStorage<T> | null | undefined;

    private loading = 0;

    constructor(private readonly lazy: boolean, private readonly simulcast = false) {
        SyncModule.modules.push(this);
    }

    stop() {
        this.subscription?.unsubscribe();
        this.subscription = undefined;
    }

    async close(reset: boolean) {
        await Promise.all(Object.keys(this.partitions).map(async removed => {
            await this.partitions[removed].close(reset);
            delete this.partitions[removed];
        }));
        if (reset) {
            const remaining = await this.eventStorage?.getPartitions();
            await Promise.all((remaining ?? []).map(async (removed) => await this.eventStorage?.close(removed)));
        }
        this.addLoading(-this.loading);
    }

    async flush() {
        await Promise.all(Object.values(this.partitions).map(async partition => {
            await partition.flush();
        }));
    }

    protected watchUser(fn: (user: UserAccount | null) => string[] | string | null) {
        return SyncService.user$.pipe(map(fn));
    }

    // Usually userId or accountIds
    abstract watchPartitions(): Observable<string[] | string | null>;
    abstract getTopic(topic: string): string;
    abstract getName(): string;

    start() {
        this.subscription = this.watchPartitions().subscribe({
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            next: async (partitionOrList) => {
                if (!partitionOrList) return; // On startup we might not have the right set of topics, let it get right

                this.addLoading(1);
                const partitions = Array.isArray(partitionOrList) ? partitionOrList : [partitionOrList];

                // If we started a partition, stop it
                Object.keys(this.partitions)
                    .filter(x => !partitions.includes(x))
                    .forEach(removed => {
                        void this.partitions[removed].close(true);
                        delete this.partitions[removed];
                    });

                // If we have a lingering partition from before startup, remove it
                const previousPartitions = await this.eventStorage?.getPartitions();
                await Promise.all(
                    (previousPartitions ?? [])
                        .filter(x => !partitions.includes(x))
                        .map(async (removed) => await this.eventStorage?.close(removed)),
                );

                // New topic, add and start it
                partitions.filter(partition => !this.partitions[partition]).forEach(partition => this.addPartition(partition));
                this.addLoading(-1);
            }, error: (error: unknown) => {
                console.error(error);
            },
        });
    }

    private addPartition(partition: string, reset = false) {
        if (!this.syncService) {
            console.warn('SyncService not initialized!');
            return;
        }

        const topic = this.getTopic(partition);
        this.partitions[partition] = new SyncPartition(this.syncService, this.storage, topic, partition, this.lazy, this.simulcast, reset, this.eventStorage);

        if (!reset) {
            this.addLoading(1);
            let loaded = false;
            this.partitions[partition].loaded$.subscribe(() => {
                if (!loaded) this.addLoading(-1);
                loaded = true;
            });
        }
        this.partitions[partition].reset$.subscribe(() => {
            delete this.partitions[partition];
            this.addPartition(partition, true);
        });
    }

    private addLoading(change: number) {
        if (!this.syncService) return;

        this.syncService.changeLoadingCount(change, this.getName());
        const subj = this.lazy ? this.syncService.lazyLoading$ : this.syncService.priorityLoading$;
        subj.next(subj.value + change);
        this.loading += change;
    }
}
