import { I18N_FULL_LANGUAGES, i18nFullLanguage } from '@weavix/models/src/translate/translate';

/**
 * Normalizes the string to our "full language" (e.g. "en-US").
 *
 * If dialect is not provided and two-letter language code is provided,
 * then we assume default dialect (e.g. "es" -> "es-MX").
 *
 * See also: {@link parseLanguage}.
 */
export function normalizeLanguage(language: string | null | undefined, dialect?: string): i18nFullLanguage | null | undefined {
    if (!language) return null;
    const locale = language.split('-')[0];

    let firstMatch: i18nFullLanguage | undefined;
    let exactMatch: i18nFullLanguage | undefined;

    for (const lang of I18N_FULL_LANGUAGES) {
        if (lang === language || (dialect && lang === `${locale}-${dialect}`)) {
            exactMatch = lang;
            break;
        }
        if (lang.split('-')[0] === locale) {
            firstMatch ??= lang;
        }
    }

    return exactMatch ?? firstMatch;
}
