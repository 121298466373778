<div class="map-page-wrapper" #mapPageWrapper
    [style.height]="height"
    [style.width]="width">
    <div class="left-panel">
        <div class="map-header" *ngIf="(features?.tableView?.tableOptions || features?.search) && mapLoaded">
            <ng-container *ngIf="features?.search">
                <app-simple-map-search
                    #mapSearch
                    [activeMapTableMode]="activeMapTableMode"
                    [searchConfig]="features?.search"
                    (searchQueryOutput)="handleTableSearch($event)"
                ></app-simple-map-search>
            </ng-container>

            <app-button-list
                class="mLa"
                [buttons]="tableModeButtons"
                (selected)="handleTableModeSelection($event)"
            ></app-button-list>
            <!-- Bulk Actions -->
            <div class="bulk-actions" *ngIf="showActionButton">
                <app-button-group
                    [hasPrimaryAction]="false"
                    [buttonText]="actionsText"
                    [class]="['pill']"
                    [showOptions]="true"
                    [options]="actionOptions"
                    openDirection="bottom-left"
                ></app-button-group>
            </div>
        </div>

        <div class="map-main">
            <div *ngIf="activeMapTableMode === mapTableMode.List" class="table-contents">
                <app-table
                    class="h100p"
                    [rows]="tableRows"
                    [options]="features?.tableView.tableOptions"
                    [externalSearchInput]="tableSearchQuery"
                    (rowClickOutput)="handleTableRowClick($event)"
                    (selectedRowsOutput)="handleRowSelection($event)"
                ></app-table>
            </div>

            <div [ngClass]="{
                    'p15px': activeMapTableMode === mapTableMode.Map,
                    'invisible': activeMapTableMode === mapTableMode.List
                }"
                class="map-contents"
            >
                
                <ng-container *ngIf="mapLoaded">
                    <ng-content select="[dashboard]"></ng-content>
            
                    <div class="dashboard-tab"
                        *ngIf="features?.hasDashboard && !dashboardVisible"
                        (click)="toggleDashboard()"
                        [matTooltip]="('map.toggle-analysis' | translate)"
                        matTooltipPosition="after"
                    >
                        <app-icon class="cPointer" [faIcon]="'fas fa-chart-bar'"></app-icon>
                    </div>
            
                    <div class="map-controls top right">
                        <app-map-control *ngIf="features?.mapControls?.recenter" class="control" [control]="features?.mapControls.recenter"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.zoomIn" class="control" [control]="features?.mapControls.zoomIn"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.zoomOut" class="control" [control]="features?.mapControls.zoomOut"></app-map-control>
                        <app-map-control class="control" *ngIf="!mapService.dashboardMode" [control]="features?.mapControls?.dashboard"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.facilityFloorPlan" class="control" [control]="features?.mapControls.facilityFloorPlan"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.geofences" class="control" [control]="features?.mapControls.geofences"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.items" class="control" [control]="features?.mapControls.items"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.mapLayers" class="control" [control]="features?.mapControls.mapLayers"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.customGroup" class="control" [control]="features?.mapControls.customGroup"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.dvr" class="control" [control]="features?.mapControls.dvr"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.toggleUnpinned" class="control" [control]="features?.mapControls.toggleUnpinned"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.viewInMap" class="control" [control]="features?.mapControls.viewInMap"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.processSurveys" class="control" [control]="features?.mapControls.processSurveys"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.fingerprintSurveys" class="control" [control]="features?.mapControls.fingerprintSurveys"></app-map-control>
                    </div>
            
                    <div class="map-controls top alert-actions">
                        <app-map-control *ngIf="features?.mapControls?.info" class="control" [control]="features?.mapControls.info"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.warning" class="control" [control]="features?.mapControls.warning"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.danger" class="control" [control]="features?.mapControls.danger"></app-map-control>
                    </div>
            
                    <div class="map-controls bottom right">
                        <ng-container *ngFor="let control of actionMapControls" >
                            <app-map-control *ngIf="!control.hide || !control.hide()" [control]="control"></app-map-control>
                        </ng-container>
                        <ng-content select="[customCallout]"></ng-content>
                    </div>

                    <div class="map-controls bottom left">
                        <ng-content select="[notShowingOnMap]"></ng-content>
                    </div>
                </ng-container>
        
                <div #map class="map"></div>
                
                <ng-container *ngIf="mapLoaded">
                    <app-dvr-controls class="dvr-controls"
                        [ngClass]="{'left-dashboard-buffer': dashboardVisible}"
                        *ngIf="map && features?.dvr?.show"
                        [type]="features?.dvr?.type"
                        [hidden]="dvrHidden"
                        [initialTime]="features?.dvr?.initialTime"
                        [range]="features?.dvr?.range"
                        [hideSlider]="features?.dvr?.hideSlider"
                        [removeDateSelection]="features?.dvr?.removeDateSelection"
                        [treatAsRangeSelection]="!!features?.dvr?.range"
                        [disabledDatesUntil]="features?.dvr?.disabledDatesUntil"
                        [disabledDatesClick]="features?.dvr?.disabledDatesClick"
                        (playbackStateUpdated)="handleDvrPlaybackStateUpdated($event)"
                        (controlsStateUpdated)="handleDvrControlsStateUpdated($event)"
                        (hideDvr)="toggleDvr()"
                        [allowPlay]="features?.dvr?.allowPlay"
                    ></app-dvr-controls>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="right-panel flexColumn hide-empty" *ngIf="!features?.mapFilter?.removeFilterPanel">
        <ng-content select="[header]"></ng-content>
        @if (showDetailView) {
            <ng-content select="[detailView]"></ng-content>
        }
        @if (!showDetailView || isCompactDetailView) {
            <ng-container *ngTemplateOutlet="mapFilter"></ng-container>
        }
    </div>
</div>

<ng-template #mapFilter>
    <ng-content select="[legend]"></ng-content>
    <div *ngIf="features?.mapFilter?.showFilterPanel && features?.mapFilter?.mapFilterResults" class="filter-container flexGrow1">
        <app-weavix-map-filter
            #mapFilters
            [hideDateRange]="true"
            [filterResults]="features?.mapFilter?.mapFilterResults"
            [multiCategorySelect]="features?.mapFilter?.multiSelect"
        ></app-weavix-map-filter>
    </div>
    <div *ngIf="features?.mapFilter?.floorPlanFilter?.show && hasFloorPlanFilterResults" class="filter-container flexGrow1">
        <app-weavix-map-filter
            #floorPlanFilters
            title="floor-plan.floor-plans"
            clearText="generics.reset"
            [hideDateRange]="true"
            [multiCategorySelect]="features?.mapFilter?.floorPlanFilter?.multiSelect ?? true"
            [filterResults]="floorPlanFilterResults"
            [ignoreViewSubscriptions]="true"
        ></app-weavix-map-filter>
    </div>
</ng-template>
