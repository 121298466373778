@if (isOpen()) {
    <!-- Absolute Left Icon -->
    @if (icon(); as icon) {
        <app-icon
            [icon]="icon"
            class="icon"
        />
    }

    <!-- Projected Content -->
    <ng-content></ng-content>

    <!-- Absolute Right Dismiss -->
    @if (dismissible()) {
        <button
            type="button"
            class="dismiss-button"
            (click)="close()"
        >
            <app-icon faIcon="fas fa-xmark" />
        </button>
    }
}
