/**
 * Our canonical set of supported language sub-tags (weavix refers to these as "locales")
 * and their region sub-tags (weavix refers to these as "dialects").
 *
 * Assume the first element in the array is the default.
 */
export const SUPPORTED_LANGUAGE_TAGS = {
    en: ['US'],
    es: ['MX', 'CU', 'SV'],
    pt: ['BR'],
    de: ['DE'],
    it: ['IT'],
    fr: ['FR'],
    my: ['MM'],
    sw: ['KE'],
    sq: ['AL'],
    ko: ['KR'],
    th: ['TH'],
    fil: ['PH'],
    ps: ['AF'],
    ja: ['JP'],
    vi: ['VN'],
    zh: ['CN'],
    uk: ['UA'],
    lo: ['LA'],
    ms: ['MY'],
    fa: ['IR'],
    ne: ['NP'],
} as const satisfies { [tag: string]: ReadonlyArray<string> & { 0: string } };

/**
 * A supported locale e.g. "en", "es", "pt".
 */
export type i18nLocale = keyof typeof SUPPORTED_LANGUAGE_TAGS;

/**
 * The list of supported locales.
 */
export const I18N_LOCALES = Object.keys(SUPPORTED_LANGUAGE_TAGS) as i18nLocale[];

/**
 * A dialect, restricted to the given locale.
 */
export type Dialect<T extends i18nLocale> = typeof SUPPORTED_LANGUAGE_TAGS[T][number];

/**
 * Any supported dialect, for all locales.
 *
 * This is a union of all dialects, so not every combination with {@link i18nLocale} is valid.
 */
export type i18nDialect = Dialect<i18nLocale>;

/**
 * A list of supported dialects.
 *
 * This is a collection of all dialects, so not every combination with {@link I18N_LOCALES} is valid.
 */
export const I18N_DIALECTS = Object.values(SUPPORTED_LANGUAGE_TAGS).flat();

/**
 * A supported locale-dialect pair.
 *
 * Use the `normalizeLocale` function in `@weavix/domain` to safely get this.
 */
export type i18nFullLanguage = {
    [T in i18nLocale]: `${T}-${Dialect<T>}`;
}[i18nLocale];

/**
 * The list of supported locale-dialect pairs.
 */
export const I18N_FULL_LANGUAGES = Object.entries(SUPPORTED_LANGUAGE_TAGS)
    .flatMap(([loc, dialects]) => dialects.map(dialect => `${loc}-${dialect}` as i18nFullLanguage));

/**
 * The native name of each language.
 *
 * These should never be translated, so they don't belong in the translation files.
 */
export const NATIVE_LANGUAGE_NAMES = {
    en: 'English',
    es: 'Español',
    'es-MX': 'Español (México)',
    'es-CU': 'Español (Cuba)',
    'es-SV': 'Español (El Salvador)',
    pt: 'Português',
    de: 'Deutsch',
    it: 'Italiano',
    fr: 'Français',
    my: 'မြန်မာ',
    sw: 'Kiswahili',
    sq: 'Shqiptare',
    ko: '한국인',
    th: 'ภาษาไทย',
    fil: 'Filipino',
    ps: 'پښتو',
    ja: '日本語',
    vi: 'Tiếng Việt',
    zh: '中国人',
    uk: 'Українська',
    lo: 'ພາສາລາວ',
    ms: 'Melayu',
    fa: 'فارسی',
    ne: 'नेपाली',
} as const satisfies {
    [key in i18nFullLanguage]: string;
} | {
    [key in i18nLocale]: string;
};
