import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Currency } from '@weavix/models/src/translation/translation';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

import { get, merge } from 'lodash';
import { Observable, ReplaySubject, zip, map } from 'rxjs';

export * from '@weavix/models/src/translation/translation';

import { environment } from 'environments/environment';
import { i18nLocale } from '@weavix/models/src/translate/translate';
import { isLocale } from '@weavix/domain/src/translate/is-locale';

declare const currencyMap: any; // /assets/currencies.js included in angular.json

export const createTranslateLoader = (http: HttpClient) => {
    const consoleLoader = new TranslateHttpLoader(http, './assets/console/i18n/', '.json');
    const radioLoader = new TranslateHttpLoader(http, './assets/radio/i18n/', '.json');

    const loaders = environment.radioApp ? [consoleLoader, radioLoader] : [radioLoader, consoleLoader];
    return {
        getTranslation(lang: string) {
            return zip(...loaders.map((l) => l.getTranslation(lang)))
                .pipe(map(v => merge(v[0], v[1])));
        },
    };
};

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    private readonly languageSubject$ = new ReplaySubject<i18nLocale>(1);
    readonly language$ = this.languageSubject$.asObservable();

    constructor(
        private readonly translate: TranslateService,
    ) {
    }

    setInitialLanguage(): void {
        const storedLocale = localStorage.getItem('lang') as i18nLocale | null;
        const browserLocale = this.translate.getBrowserLang();
        const userLocale = storedLocale ?? (isLocale(browserLocale) ? browserLocale : 'en');
        localStorage.setItem('lang', userLocale);
        this.translate.setDefaultLang(userLocale);
        this.languageSubject$.next(userLocale);
    }

    setTranslateLocale(locale: i18nLocale): void {
        localStorage.setItem('lang', locale);
        this.translate.use(locale);
        this.languageSubject$.next(locale as i18nLocale);
    }

    getTranslateLocale(): i18nLocale {
        if (!localStorage.getItem('lang')) this.setInitialLanguage();

        return localStorage.getItem('lang') as i18nLocale;
    }

    getImmediate(key: string, params?, recursive?: boolean): string {
        if (recursive) {
            params = Object.keys(params).reduce((obj, k) => {
                obj[k] = this.getImmediate(params[k]);
                return obj;
            }, {});
        }
        return this.translate.instant(key, params);
    }


    getTranslation(key: string, params?): Promise<string> {
        return this.translate.get(key, params).toPromise();
    }

    getTranslations(keys: string[], params?): Observable<{ [key: string]: string }> {
        return this.translate.get(keys, params);
    }

    getCurrencies(): Currency[] {
        return Object.keys(currencyMap).map((c) => this.getCurrency(c));
    }

    getCurrency(code: string) {
        if (!currencyMap[code]) return null;
        return {
            code: code,
            name: currencyMap[code].name,
            fractionSize: currencyMap[code].fractionSize,
            symbol: get(currencyMap[code], 'symbol.grapheme'),
        };
    }

    numberToCurrency(num: number, code: string): string {
        return new Intl.NumberFormat(this.getBrowserLocale(), { style: 'currency', currency: code }).format(num);
    }

    /**
     * NOTE: this could be two-character (e.g. "en") or full language (e.g. "en-US").
     */
    getBrowserLocale() {
        return navigator.language || navigator.languages[0] || navigator['browserLanguage'] || navigator['systemLanguage'] || navigator['userLanguage'];
    }
}
