import { SyncEventStorage } from '../sync.model';
import { LazyStorage, withStorage } from './lazy-storage';
import { NativeStorage } from './native-storage';
import { isNil, maxBy } from 'lodash';
import { IndexedStorage } from './indexed-storage';

// on application start, server returns previous 60 seconds worth of sync events
// this makes sure if we missed any while the app was shutting down we get them here
// so we track the ones we have run to not run the extras it sends back
// also mqtt does QoS 1, so we could receive the same update again on reconnection, as well as on start
const PRUNE_SYNC_MIN_MS = 120_000;

// if we are missing more than 10 records for some reason, we should probably re-sync the partition
const MAX_MISSING_RECORDS = 10;

export class PartitionEventStorage<T extends { id: string }> implements SyncEventStorage<T> {
    private readonly syncIds = new Set<string>();
    private pruneTime = 0;

    async getPartitions(): Promise<string[]> {
        return Object.keys(await this.partitionEvents.getMap());
    }

    // Keeps track of latest partition events to avoid duplicates and for querying for changes
    private readonly partitionEvents: LazyStorage<Array<{ date: string, syncId?: string, seq?: number }>>;
    private partitionSequences: Partial<Record<string, { min: number, max: number, total: number }>> = {};
    private lastSequenceCheck: Partial<Record<string, number>> = {};

    constructor(
        private readonly storage: NativeStorage,
        private readonly indexedStorage: IndexedStorage<T>,
    ) {
        this.partitionEvents = new LazyStorage(this.storage, 'partition-dates');
    }

    destroy() {
        this.partitionEvents.destroy();
    }

    clearCache() {
        this.partitionEvents.clearCache();
    }

    async flush() {
        await this.partitionEvents.flush();
    }

    async clear(partition: string, data: T[], seq: number) {
        this.syncIds.clear();
        this.partitionEvents.hold();

        this.partitionSequences[partition] = { min: seq, max: seq, total: 1 };
        this.lastSequenceCheck[partition] = seq;

        this.partitionEvents.set(partition, []);
        await this.indexedStorage.clear(partition, data);

        this.partitionEvents.unhold();
    }

    async close(partition: string) {
        this.syncIds.clear();
        this.partitionEvents.hold();

        delete this.partitionSequences[partition];
        delete this.lastSequenceCheck[partition];

        this.partitionEvents.remove(partition);
        await this.indexedStorage.removePartition(partition);

        this.partitionEvents.unhold();
    }

    /**
     * Gets the date of the latest sync event executed.
     */
    async getDateSequence(partition: string) {
        const events = await this.partitionEvents.get(partition);
        if (!events) this.partitionEvents.set(partition, []);
        if (events?.length) this.updatePruneDate(events);
        let maxSeq = 0;
        events?.forEach(event => {
            if (event.syncId) this.syncIds.add(event.syncId);
            if (event.seq && event.seq > maxSeq) maxSeq = event.seq;
        });
        this.partitionSequences[partition] = { min: maxSeq, max: maxSeq, total: 1 };
        events?.forEach(x => this.addSequence(partition, x.seq));
        const date = maxBy(events ?? [], v => v.date)?.date;
        const seq = maxBy(events ?? [], v => v.seq)?.seq;
        return { date, seq };
    }

    /**
     * Gets any sequences missing from what is stored.
     */
    async getMissingSequences(partition: string) {
        const seqs = this.partitionSequences[partition];
        if (!seqs?.total) return [];

        const { min, max, total } = seqs;

        this.lastSequenceCheck[partition] = max;
        this.partitionSequences[partition] = { min: max, max, total: 1 };

        const missing = (max - min + 1) - total;
        if (missing <= 0) return [];
        if (missing >= MAX_MISSING_RECORDS) return missing;

        const events = await this.partitionEvents.get(partition);
        const missingSequences = new Set<number>();
        for (let i = min; i <= max; i++) missingSequences.add(i);
        events?.forEach(x => {
            if (!isNil(x.seq)) missingSequences.delete(x.seq);
        });

        console.log(`Missing sequences for partition ${partition}: ${[...missingSequences].join(', ')}`);
        return [...missingSequences];
    }

    isDuplicate(date: string, syncId: string): boolean {
        return this.syncIds.has(syncId) || new Date(date).getTime() <= this.pruneTime;
    }

    /**
     * Adds a give date / syncId to the storage to keep track of the latest event run.
     */
    add(partition: string, date: string, syncId: string | undefined, seq?: number) {
        return withStorage(this.partitionEvents, partition, val => {
            val ??= [];
            if (syncId) this.syncIds.add(syncId);
            this.addSequence(partition, seq);
            val.push({ date, syncId, seq });
            this.partitionEvents.set(partition, val);
        });
    }

    prune(partition: string) {
        return withStorage(this.partitionEvents, partition, val => {
            val ??= [];
            // only prune when double to avoid pruning the array constantly
            if (new Date(val[0].date).getTime() + PRUNE_SYNC_MIN_MS * 2 < new Date(val[val.length - 1].date).getTime()) {
                this.updatePruneDate(val);
                val = val.filter(x => {
                    // keep anything at least 2 minutes from the latest date
                    if (new Date(x.date).getTime() >= this.pruneTime) return true;
                    if (!isNil(x.syncId)) this.syncIds.delete(x.syncId);
                    return false;
                });
                this.partitionEvents.set(partition, val);

                const seqs = this.partitionSequences[partition];
                if (seqs) {
                    this.partitionSequences[partition] = { min: seqs.max, max: seqs.max, total: 1 };
                }
            }
        });
    }

    private addSequence(partition: string, seq?: number) {
        const lastSeq = this.lastSequenceCheck[partition];
        if (isNil(seq) || lastSeq != null && seq < lastSeq) return;

        const seqs = this.partitionSequences[partition];
        if (!seqs) {
            this.partitionSequences[partition] = { min: seq, max: seq, total: 1 };
        } else {
            if (seqs.min > seq) seqs.min = seq;
            if (seqs.max < seq) seqs.max = seq;
            if (seqs.min < seq) seqs.total++;
        }
    }


    private updatePruneDate(events: Array<{ date: string }>) {
        const maxDateStr = maxBy(events, x => x.date)?.date;
        this.pruneTime = maxDateStr ? new Date(maxDateStr).getTime() - PRUNE_SYNC_MIN_MS : 0;
    }
}
