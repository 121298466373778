<ng-container *ngIf="modalInput && modalInput.isOpen">
    <div [class]="'modal-bg ' + (!closing ? 'fade-in' : 'fade-out')"
        [ngStyle]="{'z-index': modalInput.zIndex || 900}"
    >
        <div #modal class="modal" [ngClass]="{'full-screen': modalInput.fullScreen}"
                [style.maxWidth]="modalInput.fullScreen ? '100%' : modalInput.maxWidth ? modalInput.maxWidth : null"
                [style.width]="modalInput.fitContent ? 'fit-content' : modalInput.width + 'px'"
                [style.maxHeight]="modalInput.fullScreen ? '100%' : modalInput.maxHeight"
                [style.minWidth]="modalInput.fullScreen ? '100%' : modalInput.minWidth ? modalInput.minWidth : null"
                [style.minHeight]="modalInput.fullScreen ? '100%' : modalInput.minHeight ? modalInput.minHeight : null"
                [style.height.px]="modalInput.fullScreen ? '100%' : modalInput.height + 'px'"
                [style.overflow]='modalInput.overflow'
                [style.top]="modalInput.fullScreen ? '0' : null"
                [style.left]="modalInput.fullScreen ? '0' : null"
                [style.transform]="modalInput.fullScreen ? '0' : 'null'"
                [style.backgroundColor]="modalInput.backgroundColor ?? null"
                [style.color]="modalInput.fontColor ?? null"
                [style.padding]="modalInput.padding ?? null"
        >
            <i
                *ngIf="showClose"
                class="fa fa-times modal-close"
                [style.color]="modalInput.fontColor ?? null"
                [ngClass]="[ (modalInput.fullScreen ? 'full-screen': ''), (teamsService.isTeamsApp() ? (buttonHovers['modal-close'] ? 'fas' : 'fal') : 'fas') ]"
                (click)="handleModalClose(modalActionType.cancel)"
                (mouseenter)="buttonHovers['modal-close']=true"
                (mouseleave)="buttonHovers['modal-close']=false"
            ></i>
            <div class="modal-content" [ngClass]="{'full-screen': modalInput.fullScreen}" [style]="{'justify-content': teamsService.isTeamsApp() && !modalInput.fullScreen ? 'start' : null}">

                <div
                    class="modal-header"
                    [ngClass]="[modalInput.header?.textAlignment ? modalInput.header?.textAlignment : 'center', modalInput.fullScreen ? 'full-screen' : '']"
                    [style.font-size.px]="modalInput.header?.textSize"
                    *ngIf="modalInput.header?.textKey || modalInput.header?.secondLineTextKey">
                    <app-icon *ngIf="modalInput.header?.icon" [faIcon]="modalInput.header?.icon" style="margin-right: 15px;" ></app-icon>
                    <span *ngIf="modalInput.header?.textKey">
                        {{ modalInput.header?.textKey | translate:modalInput.params }}
                    </span>

                    <br *ngIf="modalInput.header?.secondLineTextKey"/>
                    <span *ngIf="modalInput.header?.secondLineTextKey">
                        {{ modalInput.header?.secondLineTextKey | translate:modalInput.params }}
                    </span>
                </div>

                <div *ngIf="modalInput.header?.showSeparator" class="separator"></div>
                
                <ng-container *ngIf="!modalInput.textKey || modalInput.content">
                    <ng-content></ng-content>
                </ng-container>

                <div class="modal-text" [ngClass]="{'full-screen': modalInput.fullScreen}" [style]="textDivStyle" *ngIf="modalInput.textKey" [innerHTML]="modalInput.textKey | translate:modalInput.params" ></div>
                
                <div *ngIf="modalInput.actions?.submit?.show || modalInput.actions?.delete?.show || modalInput.actions?.cancel?.show">
                    <div
                        class="modal-actions"
                        [style]="{'justify-content': teamsService.isTeamsApp() ? 'end' : ''}"
                        [ngClass]="[modalInput.actionsAlignment ? modalInput.actionsAlignment : 'stretch', modalInput.fullScreen ? 'full-screen' : '' ]"
                    >
                        <button
                            *ngIf="modalInput.actions?.cancel?.show"
                            id="modal-button-cancel" 
                            (click)="handleModalClose(modalActionType.cancel)" 
                            [class]="!teamsService.isTeamsApp() ? 'modal-actions-button' : 'teams-button'"
                            [style.width.px]="modalInput.actions?.cancel?.width ? modalInput.actions.cancel.width : ''"
                            [class.fg1]="!modalInput.actionsAlignment || modalInput.actionsAlignment === 'stretch'"
                            [ngClass]="[modalInput.actions?.cancel?.buttonClass, modalInput.fullScreen ? 'full-screen' : '' ]"
                            [disabled]="modalInput.actions.cancel.disabled ? modalInput.actions.cancel.disabled() : false"
                            [style.display]="modalInput.actions.cancel.hidden ? modalInput.actions.cancel.hidden() ? 'none' : null : null"
                        >
                            {{ modalInput.actions.cancel.textKey | translate }}
                        </button>
                        <button
                            *ngIf="modalInput.actions?.delete?.show"
                            id="modal-button-delete" 
                            (click)="handleModalClose(modalActionType.delete)" 
                            [class]="!teamsService.isTeamsApp() ? 'modal-actions-button' : modalInput.actions?.submit?.show ? 'teams-button' : 'teams-submit-button'"
                            [style.width.px]="modalInput.actions?.delete?.width ? modalInput.actions.delete.width : ''"
                            [class.fg1]="!modalInput.actionsAlignment || modalInput.actionsAlignment === 'stretch'"
                            [ngClass]="[modalInput.actions?.delete?.buttonClass, modalInput.fullScreen ? 'full-screen' : '' ]"
                            [disabled]="modalInput.actions.delete.disabled ? modalInput.actions.delete.disabled() : false"
                            [style.display]="modalInput.actions.delete.hidden ? modalInput.actions.delete.hidden() ? 'none' : null : null"
                        >
                            {{ modalInput.actions.delete.textKey | translate }}
                        </button>
                        <button
                            type="submit"
                            *ngIf="modalInput.actions?.submit?.show"
                            id="modal-button-submit"
                            (click)="handleModalClose(modalActionType.submit)"
                            [class]="!teamsService.isTeamsApp() ? 'modal-actions-button' : 'teams-submit-button'"
                            [style.width.px]="modalInput.actions?.submit?.width ? modalInput.actions.submit.width : ''"
                            [class.fg1]="!modalInput.actionsAlignment || modalInput.actionsAlignment === 'stretch'"
                            [ngClass]="[modalInput.actions?.submit?.buttonClass, modalInput.fullScreen ? 'full-screen' : '' ]"
                            [disabled]="modalInput.actions.submit.disabled ? modalInput.actions.submit.disabled() : false"
                            [style.display]="modalInput.actions.submit.hidden ? modalInput.actions.submit.hidden() ? 'none' : null : null"
                        >
                            {{ modalInput.actions.submit.textKey | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
