<div class="wrapper flexColumn jcFS aiC gap10 h100p w100p">
    @if (!isFullView) {
        <div class="header w100p">
            <div class="title">{{ titleText | translate }}</div>
        </div>
    }
    <app-table
        class="fg1 w100p h0"
        [rows]="tableRows"
        [options]="tableOptions"
        [totalItems]="data?.length"
    />
    @if (displayLoading) {
        <app-loading
            [loading]="isLoading" 
            [diameter]="50"
        />
    }
</div>
