import { DvrControlsComponent } from './dvr-controls/dvr-controls.component';
import { SimpleMapComponent } from './simple-map/simple-map.component';
import { MapControlComponent } from './weavix-map/map-control/map-control.component';
import { PersonRowDisplayComponent } from './weavix-map/weavix-map-filter/person-row-display/person-row-display.component';
import { WeavixMapFilterComponent } from './weavix-map/weavix-map-filter/weavix-map-filter.component';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [
        // We've finished the standalone migration for this module,
        // so do not add further components to this declarations array.
        // This module only exists for compatibility with non-standalone components.
    ],
    imports: [
        SimpleMapComponent,
        MapControlComponent,
        DvrControlsComponent,
        WeavixMapFilterComponent,
        PersonRowDisplayComponent,
    ],
    exports: [
        DvrControlsComponent,
        MapControlComponent,
        PersonRowDisplayComponent,
        SimpleMapComponent,
        WeavixMapFilterComponent,
    ],
})
export class ConsoleMapModule { }
