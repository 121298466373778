import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeService } from 'weavix-shared/services/themeService';
import { MapFilterResult } from 'weavix-shared/models/weavix-map.model';
import { CommonModule } from '@angular/common';
import { PersonRowDisplayComponent } from '../person-row-display/person-row-display.component';
import { ItemRowDisplayComponent } from '../item-row-display/item-row-display.component';
import { IconComponent } from 'components/icon/icon.component';

@Component({
    selector: 'app-filter-row-display',
    templateUrl: './filter-row-display.component.html',
    styleUrls: ['./filter-row-display.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        PersonRowDisplayComponent,
        IconComponent,
        ItemRowDisplayComponent,
    ],
})
export class FilterRowDisplayComponent {
    @Input() index: number = 0;
    @Input() result: MapFilterResult;
    @Output() clickEmit = new EventEmitter<MapFilterResult>();
    @Output() selectEmit = new EventEmitter<MapFilterResult>();

    lightTheme: boolean;

    constructor() {
        this.lightTheme = ThemeService.getLightTheme();
    }

    handleResClick(result: MapFilterResult): void {
        if (result?.disabled && result?.disabled()) return;
        else if (result.customClickAction) result.customClickAction();
        else this.clickEmit.emit(result);
    }

    handleSelectClick(result: MapFilterResult): void {
        if (result?.disabled && result?.disabled()) return;
        else if (result.customClickAction) result.customClickAction();
        else this.selectEmit.emit(result);
    }
}
