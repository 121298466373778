import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounce } from 'lodash';
import { Subject } from 'rxjs';
import { EntityList, EntityListItem } from 'weavix-shared/models/entity-list.model';
import { FilterResultType, MapTableMode } from 'weavix-shared/models/weavix-map.model';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';
import { FilterType } from 'components/data-filter/data-filter.model';
import { MapFeatures } from '../simple-map.component';
import { CommonModule } from '@angular/common';
import { InputComponent } from 'components/input/input.component';
import { EntityListComponent } from 'components/entity-list/entity-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideDirective } from 'weavix-shared/directives/click-outside.directive';

interface FilteredMarkerCount { showing: number, total: number }

// Note: do not imitate this pattern of global exported subjects!
export const handleMapSearchResultClick$ = new Subject<EntityListItem>();
export const updateMarkerCountDisplay = new Subject<FilteredMarkerCount>();

@AutoUnsubscribe()
@Component({
    selector: 'app-simple-map-search',
    templateUrl: './simple-map-search.component.html',
    styleUrls: ['./simple-map-search.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,

        ClickOutsideDirective,
        InputComponent,
        EntityListComponent,
    ],
})
export class SimpleMapSearchComponent implements OnInit {
    @Input() searchConfig: MapFeatures['search'];
    @Input() activeMapTableMode: MapTableMode;
    @Output() searchQueryOutput = new EventEmitter<string>();

    searchResults: EntityList;
    showResults: boolean = false;
    searchQuery: string;
    filteredMarkerCount: FilteredMarkerCount;
    debounceSearch = debounce((value: string) => this.applySearch(value), 500);

    get noResults(): boolean { return Object.values(this.searchResults).every(v => !v.length); }

    ngOnInit() {
        Utils.safeSubscribe(this, updateMarkerCountDisplay).subscribe(res => this.filteredMarkerCount = res);
    }

    handleSearch(value: string): void {
        this.debounceSearch(value);
    }

    private setSearchQuery(value: string): void {
        this.searchQuery = value?.length ? value : null;
    }

    private applySearch(value: string): void {
        this.setSearchQuery(value);
        if (this.activeMapTableMode === MapTableMode.Map) this.applyMapSearch(value);
        else this.searchQueryOutput.emit(value);
    }

    private applyMapSearch(query: string): void {
        if (this.searchQuery) {
            this.setSearchResults(query);

            if (!this.noResults) this.showResultsList();
            else this.hideResultsList();
        } else {
            this.clearSearch();
        }
    }

    private setSearchResults(searchValue: string): void {
        this.searchResults = {};

        Object.keys(this.searchConfig.types).forEach(type => {
            switch (type) {
                case FilterResultType.Person : {
                    const peopleResults = this.searchPeople(searchValue);
                    this.searchResults[FilterResultType.Person] = peopleResults;
                    break;
                }
                case FilterType.Geofence : {
                    const geoResults = this.searchGeofences(searchValue);
                    this.searchResults[FilterResultType.Geofence] = geoResults;
                    break;
                }
                case FilterType.Walt : {
                    const waltResults = this.searchWalts(searchValue);
                    this.searchResults[FilterResultType.Walt] = waltResults;
                    break;
                }
            }
        });
    }

    private searchPeople(searchValue: string): EntityListItem[] {
        const people = this.searchConfig.types.person();
        return people
            .filter(p => p.fullName.toLowerCase().includes(searchValue.toLowerCase()))
            .map(p => ({ id: p.id, type: FilterResultType.Person, name: p.fullName, entity: p }));
    }

    private searchGeofences(searchValue: string): EntityListItem[] {
        const geofences = this.searchConfig.types.geofence() ?? [];
        return geofences
            .filter(g => g.name.toLowerCase().includes(searchValue.toLowerCase()))
            .map(g => ({ id: g.id, type: FilterResultType.Geofence, name: g.name, entity: g }));
    }

    private searchWalts(searchValue: string): EntityListItem[] {
        const walts = this.searchConfig.types.walt() ?? [];
        return walts
            .filter(w => w.id.toLowerCase().includes(searchValue.toLowerCase()) || w.person?.fullName?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
            .map(w => ({ id: w.id, type: FilterResultType.Walt, name: w.id, entity: w }));
    }

    showResultsList(): void {
        this.showResults = true;
    }

    hideResultsList(): void {
        this.showResults = false;
    }

    handleItemClick(item: EntityListItem): void {
        this.hideResultsList();
        handleMapSearchResultClick$.next(item);
    }

    public clearSearch(): void {
        this.hideResultsList();
        this.setSearchQuery(null);
    }
}
