import { Component, computed, effect, inject, input, output, signal } from '@angular/core';
import { Modal, ModalActions, ModalActionType, ModalService } from 'weavix-shared/services/modal.service';
import { debounce } from 'lodash';
import { AlertService, ServiceError } from 'weavix-shared/services/alert.service';
import { CompanyService } from 'weavix-shared/services/company.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { WaltService } from 'weavix-shared/services/walt.service';
import { FEATURE_ICONS } from 'weavix-shared/utils/feature.icons';
import { AutoUnsubscribe, getBatteryIcon } from 'weavix-shared/utils/utils';
import { FacilityService } from 'weavix-shared/services/facility.service';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { PermissionAction } from '@weavix/models/src/permission/permissions.model';
import { Facility } from 'weavix-shared/models/facility.model';
import { DropdownItem } from 'components/dropdown/dropdown.model';
import { LoadingComponent } from 'components/loading/loading.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from 'components/icon/icon.component';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from 'components/dropdown/dropdown.component';
import { FormsModule } from '@angular/forms';
import { AvatarComponent } from 'components/avatar/avatar.component';
import { ModalComponent } from 'components/modal/modal.component';
import { TimeAgoPipe } from 'weavix-shared/pipes/time-ago.pipe';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { catchError, distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';
import { MapWalt } from 'weavix-shared/models/weavix-map.model';

@AutoUnsubscribe()
@Component({
    selector: 'app-walt-detail',
    templateUrl: './walt-detail.component.html',
    styleUrls: ['./walt-detail.component.scss', '../map-detail-view.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,

        AvatarComponent,
        IconComponent,
        DropdownComponent,
        LoadingComponent,
        ModalComponent,
        TimeAgoPipe,
    ],
})
export class WaltDetailComponent {
    private readonly alertService = inject(AlertService);
    private readonly companyService = inject(CompanyService);
    private readonly waltService = inject(WaltService);
    private readonly modalService = inject(ModalService);
    private readonly translationService = inject(TranslationService);
    private readonly facilityService = inject(FacilityService);
    private readonly profileService = inject(ProfileService);

    readonly walt = input.required<MapWalt>();
    readonly closeOutput = output<void>();

    readonly loadingCount = signal(0);
    readonly waltIcon = FEATURE_ICONS.radio.icon;
    readonly batteryIcon = computed(() => getBatteryIcon(this.walt().battery * 100));
    private readonly walt$ = toObservable(this.walt);
    readonly company = toSignal(this.walt$.pipe(
        map(walt => walt.lastAssigned?.companyId),
        distinctUntilChanged(),
        tap(() => this.addLoading()),
        switchMap(async companyId => {
            if (!companyId) return null;
            else return await this.companyService.get(this, companyId);
        }),
        catchError((e: any) => {
            this.alertService.sendServiceError(e, ServiceError.Get, 'walt.walt');
            return of(null);
        }),
        tap(() => this.removeLoading()),
    ));
    readonly lastAssigned = computed(() => this.waltService.getWaltLastAssignedName(this.walt()));
    readonly person = toSignal(this.walt$.pipe(
        distinctUntilChanged((prev, curr) => {
            return prev.person === curr.person
                && prev.lastAssigned?.personId === curr.lastAssigned?.personId
                && prev.wrangler?.id === curr.wrangler?.id
                && prev.wrangler?.date.getDate() === curr.wrangler?.date.getDate();
        }),
        tap(() => this.addLoading()),
        switchMap(async walt => {
            const currentlyAssigned = WaltService.isCurrentlyAssigned(walt);
            const lastAssignedName = this.waltService.getWaltLastAssignedName(walt);
            // handles edge case where person is fully removed from the database...
            if (currentlyAssigned &&
                lastAssignedName !== this.translationService.getImmediate('generics.unknown')
            ) {
                return await this.waltService.getWaltLastAssignedPerson(this, walt);
            } else {
                return null;
            }
        }),
        catchError((e: any) => {
            this.alertService.sendServiceError(e, ServiceError.Get, 'walt.walt');
            return of(null);
        }),
        tap(() => this.removeLoading()),
    ));
    readonly hasDeviceManagementEditPerms = computed(() => {
        const walt = this.walt();
        return this.profileService.hasFacilitiesPermission(PermissionAction.EditDeviceManagement, walt.facilityId);
    });
    readonly hasDeviceNetworkStatusPerms = computed(() => {
        const walt = this.walt();
        return this.profileService.hasPermission(PermissionAction.EnableDeviceNetworkStatus, walt.facilityId);
    });
    readonly canLogOut = computed(() => {
        const walt = this.walt();
        if (!this.hasDeviceManagementEditPerms())
            return false;
        else if (WaltService.isCurrentlyAssigned(walt) && WaltService.waltActive(walt) && !walt.poweredOff)
            return true;
        else
            return false;
    });
    readonly facilities = toSignal(this.walt$.pipe(
        map(walt => walt.facilityId),
        distinctUntilChanged(),
        tap(() => this.addLoading()),
        switchMap(async facilityId => {
            return (await this.facilityService.getMyFacilitiesOnCurrentAccount(this))
                .filter(x => x.id === facilityId || this.profileService.hasFacilitiesPermission(PermissionAction.EditDeviceManagement, x.id));
        }),
        catchError((e: any) => {
            this.alertService.sendServiceError(e, ServiceError.Get, 'walt.walt');
            return of([] as Facility[]);
        }),
        tap(() => this.removeLoading()),
    ), { initialValue: [] });
    readonly facilityDropdown = computed(() => this.facilities().map(f => {
        return {
            label: f.name,
            key: f.id,
        };
    }));
    private readonly modalActions: ModalActions = {
        cancel: {
            show: true,
            textKey: 'generics.no',
        },
        submit: {
            show: true,
            textKey: 'generics.yes',
            buttonClass: 'blue-lt',
        },
    };
    readonly waltFacility = signal<string | undefined>(undefined);
    readonly changeFacility = signal<Modal | null>(null);

    constructor() {
        // TODO(Angular 19): use linkedSignal for waltFacility instead.
        // We want a signal that initializes from walt(), so effect() is the only way pre-Angular 19.
        effect(() => {
            const walt = this.walt();
            this.waltFacility.set(walt.facilityId);
        }, { allowSignalWrites: true });
    }

    private addLoading() {
        this.loadingCount.update(n => n + 1);
    }

    private removeLoading() {
        this.loadingCount.update(n => n - 1);
    }

    onFacilityOutput(selected: DropdownItem) {
        this.waltFacility.set(selected.key);
        const facility = this.facilities().find(f => f.id === selected.key);
        this.changeFacility.set({
            isOpen: true,
            width: 450,
            actionsAlignment: 'right',
            header: {
                textKey: 'walt.site-change.header',
                textAlignment: 'left',
                showSeparator: true,
            },
            actions: {
                [ModalActionType.submit]: {
                    textKey: 'generics.confirm',
                    show: true,
                },
                [ModalActionType.cancel]: {
                    textKey: 'generics.cancel',
                    show: true,
                },
            },
            fullScreen: false,
            content: false,
            textAlignment: 'left',
            padding: 20,
            textKey: 'walt.site-change.body',
            textStyle: {
                'padding-top': '20px',
                'padding-bottom': '10px',
                'font-size': '16px',
            },
            params: { siteName: facility.name },
        });
    }

    async updateWaltFacility(action: ModalActionType) {
        this.changeFacility.set(null);
        if (action !== ModalActionType.submit) {
            this.waltFacility.set(this.walt().facilityId);
            return;
        }
        try {
            this.alertService.setAppLoading(true);
            await this.waltService.setWaltFacility(this, this.walt().id, this.waltFacility());
            this.alertService.sendSuccess({ messageKey: 'walt.site-change.success' });
        } catch (e: any) {
            this.waltFacility.set(this.walt().facilityId);
            this.alertService.sendServiceError(e, ServiceError.Update, 'walt.walt');
        } finally {
            this.alertService.setAppLoading(false);
        }
    }

    private readonly debouncePlaySound = debounce(async () => await this.waltService.playWaltSound(this, this.walt().id), 500);
    async playSound() {
        await this.debouncePlaySound();
    }

    private readonly debounceLogout = debounce(async () => await this.waltService.remoteLogout(this, this.walt().id), 500);
    async remoteLogout() {
        const modalResult = await this.modalService.confirm('', this.translationService.getImmediate('walt.confirm-remote-logout'), this.modalActions, true);
        if (modalResult.action === ModalActionType.submit)
            await this.debounceLogout();
    }

    handleClose() {
        this.closeOutput.emit();
    }
}
