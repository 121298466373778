import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ModalComponent } from 'components/modal/modal.component';
import { Modal } from 'components/modal/modal.model';
import { PeopleListComponent } from 'components/people-list/people-list.component';
import { PersonSelect } from 'components/person-select/person-select.model';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-people-preview-modal',
    templateUrl: './people-preview-modal.component.html',
    styleUrls: ['./people-preview-modal.component.scss'],
    standalone: true,
    imports: [
        CommonModule,

        ModalComponent,
        PeopleListComponent,
    ],
})
export class PeoplePreviewModalComponent implements OnChanges {
    @Input() open = false;
    @Input() data: PersonSelect[] = [];
    @Output() closed = new EventEmitter();

    modal = this.createModal();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            this.data = changes.data.currentValue;
        }
        if (changes.open) {
            this.modal.isOpen = changes.open.currentValue;
        }
    }

    async handleModalClose() {
        this.modal.isOpen = false;
        this.closed.emit();
    }

    private createModal(): Modal {
        return {
            isOpen: false,
            height: 770,
            width: 1160,
            zIndex: 910,
            header: {
                icon: 'fas fa-users',
                showSeparator: true,
                textAlignment: 'left',
                textKey: 'people-preview.title',
                textSize: 18,
            },
        };
    }
}
