import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Utils } from 'weavix-shared/utils/utils';

export interface ChartLegendItem {
    color: string;
    name: string;
    seriesDataKey: any; // key to map to series data on chart
    value?: {
        actual: number;
        display?: string;
    };
    selected?: boolean;
}

@Component({
    selector: 'app-chart-legend',
    templateUrl: './chart-legend.component.html',
    styleUrls: ['./chart-legend.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
    ],
})
export class ChartLegendComponent implements OnChanges {
    @Output() itemSelection = new EventEmitter<ChartLegendItem>();
    @Input() selectable: boolean = false;
    @Input() legendData: ChartLegendItem[];

    ngOnChanges() {
        this.sortList();
     }

    handleItemSelection(item: ChartLegendItem): void {
        if (!item.selected) this.legendData.forEach(x => x.selected = false);
        item.selected = !item.selected;
        this.itemSelection.emit(item);
    }

    sortList(): void {
        const sortAlpha: boolean = this.legendData.every(item => item.value === undefined);
        this.legendData = sortAlpha
            ? Utils.sortAlphabetical(this.legendData, (item) => item.name)
            : Utils.sortByNumber<ChartLegendItem>(this.legendData, item => Number(item.value?.actual) ?? 0, false);
        this.popAndAppendOtherItem();
    }

    private popAndAppendOtherItem(): void {
        const otherIndex: number = this.legendData.findIndex(item => item.seriesDataKey === 'other');
        if (otherIndex > -1) this.legendData.push(this.legendData.splice(otherIndex, 1)[0]);
    }
}
