import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from 'components/icon/icon.component';

export interface ButtonListOptions {
    deselectedButtonColor?: string;
    deselectedTextColor?: string;
}

export interface ListButton<T = string> {
    textKey: string;
    keyValue: T;
    selected?: boolean;
    icon?: string;
    disabled?: boolean;
}

@Component({
    selector: 'app-button-list',
    templateUrl: './button-list.component.html',
    styleUrls: ['./button-list.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,

        IconComponent,
    ],
})
export class ButtonListComponent<T> implements OnInit {
    @Input() options: ButtonListOptions;
    @Input() buttons: Array<ListButton<T>> = [];

    @Output() selected = new EventEmitter<T>();

    ngOnInit(): void {
        if (this.buttons?.length && !this.buttons.find(b => b.selected)) this.selectButton(this.buttons[0].keyValue);
    }

    selectButton(keyValue: T, e?: Event) {
        e?.stopPropagation();
        this.buttons.forEach(b => b.selected = b.keyValue === keyValue);
        this.selected.emit(keyValue);
    }

}
