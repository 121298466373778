// eslint-disable-next-line @typescript-eslint/no-unused-expressions
global?.WEAVIX_LOGGING_SYNC === 'none';

declare global {
    // eslint-disable-next-line no-var
    var WEAVIX_LOGGING_SYNC: string | undefined;
}

export function syncLogVerbose(cb: () => string): void {
    // verbose logging can be enabled on-the-fly in the browser console
    if (global?.WEAVIX_LOGGING_SYNC === 'VERBOSE') {
        console.log('VERBOSE Sync:', cb());
    }
}
