import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { AutoUnsubscribe, RADIO_WINDOW_TARGET, Utils } from 'weavix-shared/utils/utils';
import { CardType } from 'components/card-select/card-select.model';
import { environment } from 'environments/environment';
import { Account } from 'weavix-shared/models/account.model';
import { NotificationCenterEntry } from '@weavix/models/src/notification/notification-center';
import { UserProfile } from 'weavix-shared/models/user.model';
import { AccountService } from 'weavix-shared/services/account.service';
import { AcsService } from 'weavix-shared/services/acs.service';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { NotificationCenterService, closeNotification$, closeNotificationDropdown$, openNotification$, updateNotificationIcon$ } from 'weavix-shared/services/notification-center.service';
import { NotificationOverlayService } from './services/notification-overlay-service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { UserBadge } from '@weavix/models/src/user/User';
import { getRadioWindowFeatures } from 'weavix-shared/utils/utils';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from 'components/icon/icon.component';
import { NotificationCardComponent } from './notification-card/notification-card.component';
import { ClickOutsideDirective } from 'weavix-shared/directives/click-outside.directive';

@AutoUnsubscribe()
@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss', './notifications-teams.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatTooltipModule,
        TranslateModule,

        ClickOutsideDirective,

        IconComponent,
        NotificationCardComponent,
    ],
})
export class NotificationsComponent implements OnInit {
    @Input() sidebarOpen: boolean = false;
    @Output() reloadChannels = new EventEmitter<string>();
    public CardType = CardType;

    account: Account;
    userProfile: UserProfile;
    notifications: NotificationCenterEntry[] = [];
    hasNotifications: boolean = false;
    notificationCount: number = 0;
    showNotifications: boolean = false;
    ignoreToggle: boolean = false;
    teamsApp = environment.teamsApp;
    consoleApp = environment.consoleApp;

    constructor(
        private notificationCenterService: NotificationCenterService,
        private notificationOverlayService: NotificationOverlayService,
        private accountService: AccountService,
        private profileService: ProfileService,
        private translationService: TranslationService,
        private swPush: SwPush,
        private acsService: AcsService,
    ) { }

    ngOnInit() {
        this.swPush.notificationClicks.subscribe(
            ({ notification }) => {
                const data = notification.data;
                if (data.topic !== 'person-connection') return;
                this.toggleOverlay();
            });
        if (this.consoleApp) this.getBadgeData();
        else this.getNotificationData();
        Utils.safeSubscribe(this, openNotification$).subscribe(() => {
            this.ignoreToggle = true;
        });
        Utils.safeSubscribe(this, closeNotification$).subscribe(() => {
            this.ignoreToggle = false;
        });
        Utils.safeSubscribe(this, closeNotificationDropdown$).subscribe(() => {
            this.showNotifications = false;
        });
        Utils.safeSubscribe(this, updateNotificationIcon$).subscribe(() => {
            this.updateNotificationIcon();
        });
    }

    getVisibility(): boolean {
        return this.notificationOverlayService.overlayIsVisible;
    }

    async getNotificationData(): Promise<void> {
        this.account = await this.accountService.getAccount();
        this.userProfile = await this.profileService.getUserProfile(this);
        // right now all we care about are Person Connections for 5.0 release
        // the filter is unnecessary when we want all notifications
        this.notifications = await this.notificationCenterService.getAll(this);
        const locale = this.translationService.getTranslateLocale();
        this.notifications.forEach(x => {
            if (x.translations) {
                const found = Object.keys(x.translations).find(txLocale => {
                    return txLocale.split('-')[0] === locale.split('-')[0];
                });
                x.translatedMessage = found ? x.translations[found] : x.message;
            } else x.translatedMessage = x.message;
        });
        this.notifications = Utils.sortByDate(this.notifications, 'created', false);
        this.subscribeToNewNotifications();
        this.updateNotificationIcon();
    }

    async getBadgeData(): Promise<void> {
        const badges = await this.profileService.refreshMyBadge(this);

        Utils.safeSubscribe(this, await this.profileService.subscribeNotifications(this))
            .subscribe(notif => this.updateConsoleCount(badges, notif.payload.badges, true));
        Utils.safeSubscribe(this, await this.profileService.subscribeBadgeUpdates(this))
            .subscribe(notif => this.updateConsoleCount(badges, notif.payload, true));

        this.updateConsoleCount(badges);
    }

    private updateConsoleCount(previous: UserBadge, badge?: UserBadge, updateUserProfile?: boolean) {
        if (updateUserProfile) this.profileService.updateUserProfileBadges(badge);

        if (previous) {
            previous.messages = badge?.messages ?? previous?.messages ?? 0;
            previous.alerts = badge?.alerts ?? previous?.alerts ?? 0;
        }
        this.notificationCount = Math.min(99, previous?.messages + previous?.alerts);
        this.hasNotifications = this.notificationCount > 0;
    }

    public updateNotificationIcon(): void {
        this.notificationCount = this.getUnreadCount();
        this.hasNotifications = this.notificationCount > 0;
    }

    public removeNotification(id: string): void {
        this.notificationCenterService.update(this, id, { read: true, removed: true });
    }

    public notificationButtonAction(e?: MouseEvent) {
        e?.stopPropagation();
        if (this.consoleApp) {
            if (e?.button === 1) window.open(environment.radioUrl, RADIO_WINDOW_TARGET);
            else window.open(environment.radioUrl, RADIO_WINDOW_TARGET, getRadioWindowFeatures());
        } else this.showNotifications = !this.showNotifications;
    }

    public toggleNotificationsDisplay(): void {
        if (!this.ignoreToggle) {
            this.showNotifications = !this.showNotifications;
        }
    }

    private toggleOverlay(): void {
        this.notificationOverlayService.toggleOverlay();
    }

    public onOverlayCloseClick(e?: Event): void {
        if (e) e.stopPropagation();
        this.notificationOverlayService.hideOverlay();
    }

    public onOverlayBackClick(): void {
        this.onOverlayCloseClick();
    }

    private getUnreadCount(): number {
        return this.notifications.filter(n => !n.read).length;
    }

    private subscribeToNewNotifications() {
        Utils.safeSubscribe(this, this.notificationCenterService.subscribeNotificationCreated(this, this.userProfile.id)).subscribe( n => {
            const notification = n.payload;
            const existing = this.notifications.find(e => e.id === notification.id);
            const index = this.notifications.findIndex(e => e.id === notification.id);
            if (existing) {
                existing.read = notification.read;
                existing.acknowledgement = notification.acknowledgement;
                existing.removed = notification.removed;
                this.notifications.splice(index, 1);
                if (!existing.removed) this.notifications.push(existing);
            } else {
                if (notification.translations) {
                    const locale = this.translationService.getTranslateLocale();
                    const found = Object.keys(notification.translations).find(txLocale => {
                        return txLocale.split('-')[0] === locale.split('-')[0];
                    });
                    notification.translatedMessage = found ? notification.translations[found] : notification.message;
                } else notification.translatedMessage = notification.message;
                this.notifications.push(notification);
            }
            this.notifications = Utils.sortByDate(this.notifications, 'created', false);
            this.notificationCenterService.currentNotifications = this.notifications;
            this.updateNotificationIcon();
            if (this.acsService.isOnACall()) openNotification$.next(notification);
        });
    }
}
