import { sleep } from './sleep';


/**
 * Batches a bunch of inputs together. After a delay, calls the callback with an array of all the batched inputs.
 *
 * @class Batcher
 * @template T The input value type
 * @template S The output value type
 */
export class Batcher<T, S = any> {
    private queue: T[] = [];
    private promise: Promise<S[]> | undefined;

    constructor(
        private readonly batch: (req: T[]) => Promise<S[]>,
        private readonly delay: number = 50,
    ) {}

    async add(value: T): Promise<S> {
        if (!this.queue.length) {
            this.promise = (async () => {
                await sleep(this.delay);
                const list = this.queue;
                this.queue = [];
                return this.batch(list);
            })();
        }
        const index = this.queue.length;
        this.queue.push(value);

        const results = await this.promise!;
        const result = results[index];
        return result;
    }

    async wait() {
        return this.promise;
    }
}
