import { Context, createStore, ExtendedModel, idProp, model, Model, prop } from '@weavix/mobx';
import { PermissionAction } from '@weavix/models/src/permission/permissions.model';
import { myCompanyId } from '../my-profile-store/my-profile-store';

export const myCompany = () => {
    return companiesContext.get().get(myCompanyId());
};

@model('Company')
export class Company extends Model({
    id: idProp,
    name: prop<string>(() => ''),
    image: prop<string>(),
    isNew: prop<boolean>(),
    color: prop<string>(),
    settings: prop<{ metric?: boolean }>(),
    features: prop<Array<{ key: string, value: number }>>(),
    enabledActions: prop<PermissionAction[]>(),
    master: prop<boolean>(),
}) {
    get logo() {
        return this.image;
    }

    get searchText() {
        return this.name;
    }

    get initials() {
        return this.name?.split(' ').splice(0, 2).map(x => x.substr(0, 1)).join(' ').toUpperCase();
    }
}

const base = createStore<Company>('companies', Company);
export const companiesContext = base.context as Context<CompaniesStore>;
export const companyRef = base.ref;

@model('CompaniesStore')
export class CompaniesStore extends ExtendedModel(base.Store, {
}) {
    get list() {
        return this.getAll();
    }
}
