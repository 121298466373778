import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Facility } from '@weavix/models/src/facility/facility';
import { PermissionAction } from '@weavix/models/src/permission/permissions.model';
import { Chip, ChipListComponent } from 'components/chip-list/chip-list.component';
import { FacilityService } from 'weavix-shared/services/facility.service';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

type SiteType = 'channels' | 'forms' | 'alert-types' | 'workflows'
    | 'crafts' | 'geofence-types' | 'item-types';

const EDIT_PERMISSIONS: { [key in SiteType]: PermissionAction } = {
    channels: PermissionAction.EditAdministratorDefinedChannels,
    forms: PermissionAction.EditForms,
    'alert-types': PermissionAction.EditAlertTypes,
    workflows: PermissionAction.EditWorkflows,
    crafts: PermissionAction.EditCrafts,
    'geofence-types': PermissionAction.EditGeofenceTypes,
    'item-types': PermissionAction.EditItemTypes,
};

@AutoUnsubscribe()
@Component({
    selector: 'app-site-chip-list',
    styleUrls: ['./site-chip-list.component.scss'],
    templateUrl: './site-chip-list.component.html',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        TranslateModule,

        ChipListComponent,
    ],
})
export class SiteChipListComponent implements OnInit {
    @Input() type: SiteType;
    @Input() folderId?: string;
    @Input() control: AbstractControl<string[]>;
    @Input() globalAdmin = false;

    get formControl() {
        return this.control as FormControl<string[]>;
    }

    sites: Facility[] = [];
    siteChips: Chip[] = [];

    get editable() {
        return ['channels', 'forms', 'workflows'].includes(this.type);
    }

    get isSitesDisabled(): boolean { return this.siteChips.length === 1 || !this.editable; }

    getSuggestedName(name: string) {
        let suggestedName = name;
        const selectedSiteChips = this.siteChips.filter(chip => this.control.value.includes(chip.id));
        if (this.isSitesDisabled || selectedSiteChips.length === 1) {
            suggestedName += `-${selectedSiteChips[0].name}`;
        } else {
            suggestedName += this.translationService.getImmediate('rField.site.site-name');
        }
        return suggestedName;
    }

    public constructor(
        private facilityService: FacilityService,
        private profileService: ProfileService,
        private translationService: TranslationService,
    ) { }

    async ngOnInit() {
        const isNew = !this.control.value;
        const canEdit = this.editable &&
            (isNew || this.profileService.hasFacilitiesPermission(EDIT_PERMISSIONS[this.type], this.control.value, this.folderId, true));

        const facilities = await this.facilityService.getAll(this);
        this.sites = !canEdit ? facilities
            : facilities.filter(site => this.profileService.hasPermission(EDIT_PERMISSIONS[this.type], site.id, this.folderId));
        this.siteChips = this.sites.map(site => ({ id: site.id, name: site.name, isDisabled: () => this.isAllSelected }) as Chip);

        if (this.globalAdmin) {
            this.siteChips.unshift({ id: undefined, name: this.translationService.getImmediate('rField.site.global') });
        } else if (!canEdit || this.profileService.hasPermission(EDIT_PERMISSIONS[this.type], null, this.folderId)) {
            this.siteChips.unshift({ id: undefined, name: this.translationService.getImmediate('rField.site.all-sites') });
        }

        if (isNew) {
            this.control.setValue(
                this.siteChips.length === 1 && this.editable ? this.siteChips.map(v => v.id) : [],
            );
        } else if (!this.control.value.length && (this.editable || this.globalAdmin)) {
            this.isAllSelected = true;
            this.control.setValue([undefined]);
        }
    }

    private isAllSelected = false;
    async onSitesChanged(values: string[]): Promise<void> {
        this.isAllSelected = values.includes(undefined);
        if (this.isAllSelected) this.control.setValue([undefined]);
    }
}
