<!-- Icon -->
@if (icon(); as icon) {
    <app-icon
        [icon]="icon"
        class="icon"
    />
}

<!-- Projected Content -->
<ng-content></ng-content>
