import {
    computed,
    Context,
    createStore,
    ExtendedModel,
    idProp,
    Model,
    model,
    prop,
} from '@weavix/mobx';
import { SyncService } from '@weavix/sync';
import { BadgeUpdate } from '@weavix/models/src/badges/badge-update';
import { companiesContext } from '../companies-store/companies-store';
import { craftsContext } from '../crafts/crafts-store';
import { myUserId } from '../my-profile-store/my-profile-store';
import { accountPersonRef } from '../people-store/people-store';
import { environmentContext } from '../environment';
import { Topic } from '@weavix/models/src/topic/topic';
import { LazyObservable } from '@weavix/utils/src/lazy-observable';
import { TeamsIdentity } from '@weavix/models/src/user/User';
import { i18nDialect, i18nLocale } from '@weavix/models/src/translate/translate';

export const myUser = () => usersContext.getDefault().get(myUserId());

// unsubscribe from availability updates after this long of no one being interested
const UNSUBSCRIBE_AVAILABILITY_AFTER = 60000;

@model('User')
export class User extends Model({
    id: idProp,
    aboutMe: prop<string>(),
    avatarFile: prop<string>(),
    bleIds: prop<number[]>(),
    companyId: prop<string>(),
    email: prop<string>(),
    firstName: prop<string>(),
    lastName: prop<string>(),
    locale: prop<i18nLocale>(),
    dialect: prop<i18nDialect>(),
    middleName: prop<string>(),
    phone: prop<string>(),
    accountIds: prop<string[]>([]),
    deleted: prop<boolean>(),
    teamsOnlyUser: prop<boolean>(),
    teamsId: prop<TeamsIdentity>(),
    acsUserId: prop<string>(),
}) {
    private badges: BadgeUpdate[] = [];

    available$ = new LazyObservable<boolean>(UNSUBSCRIBE_AVAILABILITY_AFTER, () => this.startAvailabilityUpdates());

    private startAvailabilityUpdates() {
        const accountIds = this.myAccountIds ?? this.accountIds ?? [];
        const subs = accountIds.map(a => {
            return environmentContext.get().pubSubService.subscribe<any>(null, Topic.AccountPersonFacilitiesUpdated, [a, this.id]).subscribe(({ payload }) => {
                this.updateAccountBadge(a, payload);
            });
        });
        return () => subs.forEach(x => x.unsubscribe());
    }

    @computed
    get company() {
        return companiesContext.get().get(this.companyId);
    }

    get myAccountIds() {
        return myUser().accountIds.filter(v => this.accountIds.includes(v));
    }

    get accounts() {
        return this.accountIds.map(x => companiesContext.get().get(x));
    }

    @computed
    get fullName() {
        return `${this.firstName?.trim() ?? ''} ${this.lastName?.trim() ?? ''}`.trim();
    }

    @computed
    get searchText() {
        return `${this.fullName}|${this.company?.name ?? ''}|${this.email}|${this.phone}`?.toLowerCase();
    }

    @computed
    get firstNameLastInitial() {
        return `${this.firstName?.trim() ?? ''} ${this.lastName?.trim()?.[0] ?? ''}`.trim();
    }

    get available(): boolean {
        return this.badges.some(x => x.facilities?.length);
    }

    get connected(): boolean {
        return this.badges.some(x => new Date().getTime() - new Date(x.heartbeat).getTime() < 5 * 60000);
    }

    get companyName() {
        return this.company?.name;
    }

    get companyLogo() {
        return this.company?.logo;
    }

    get companyColor() {
        return this.company?.color;
    }

    @computed
    get crafts() {
        return [].concat(
            ...this.accountIds.map(x => accountPersonRef(x, this.id).maybeCurrent?.crafts ?? []),
        );
    }

    @computed
    get accountPerson() {
        const resp = [];
        this.accountIds.map(x => resp.push(accountPersonRef(x, this.id).maybeCurrent));
        resp.push(accountPersonRef(this.companyId, this.id).maybeCurrent);
        return resp.filter(x => x);
    }

    @computed
    get craftNames() {
        return this.crafts.map(x => craftsContext.get().get(x)?.name).filter(x => x);
    }

    get badge() {
        return null;
    }

    get onSiteAccounts() {
        return this.badges.filter(x => x.facilities?.length).map(x => x.accountId);
    }

    get onSiteBadges() {
        return this.badges.filter(x => x.facilities?.length).filter(x => this.onSiteAccounts.includes(x.accountId));
    }

    updateAccountBadge(accountId: string, badge: BadgeUpdate) {
        (badge as any).accountId = accountId;
        const found = this.badges.findIndex(x => (x as any).accountId === accountId);
        if (found >= 0) this.badges[found] = badge;
        else this.badges.push(badge);
        this.available$.next(this.available);
    }
}

const base = createStore<User>('users', User);

export const usersContext = base.context as Context<UsersStore>;
export const userRef = base.ref;

@model('UsersStore')
export class UsersStore extends ExtendedModel(base.Store, {
}) {

    getUserByTeamsId(teamsId: string) {
        return usersContext.getDefault().getAllStatic(user => user?.teamsId?.id === teamsId)[0] || null;
    }

    getUserByAcsId(acsUserId: string) {
        return usersContext.getDefault().getAllStatic(user => user?.acsUserId === acsUserId)[0] || null;
    }

    async searchUsers(text: string) {
        try {
            const users = await SyncService.instance.get<any[]>('/sync/users/search', { text });
            return users.map(user => {
                return this.get(user.id, user);
            });
        } catch (e) {
            console.error(e);
        }
    }
}
