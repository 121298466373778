import { Context, createStore, ExtendedModel, idProp, Model, model, prop } from '@weavix/mobx';

@model('Feature')
export class Feature extends Model({
    id: idProp,
    name: prop<string>(),
    description: prop<string>(),
    defaultValue: prop<boolean>(),
    lastUpdated: prop<Date>(),
}) {

}

const base = createStore<Feature>('features', Feature);
export const featuresContext = base.context as Context<FeaturesStore>;

@model('FeaturesStore')
export class FeaturesStore extends ExtendedModel(base.Store, {}) {
}