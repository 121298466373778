import { getAccountStorage } from '@weavix/mobx';
import { NativeStorage } from '@weavix/sync';
import { WebNativeStorage } from 'weavix-shared/services/sync/web-native-storage';
import { ChannelMessage } from './channel-message';
import { coerceToDate } from '@weavix/utils/src/coerce-to-date';

interface ChannelArchiveItem {
    archived?: Date;
}

export class ChannelMessageArchiver {
    constructor(
        private storage: NativeStorage = new WebNativeStorage('channel-archive'),
    ) { }

    async archiveChannelMessages(channelId: string, archived: Date, messageTopic: string): Promise<Set<string>> {
        const prevArchived = (await this.getArchived(channelId) ?? new Date(0));
        if (!archived || (archived.getTime() <= prevArchived.getTime())) return new Set();
        await this.setArchived(channelId, archived);
        return new Promise((resolve) => {
            console.log(`Archiving messages for ${channelId} older than ${archived.toISOString()}`);
            const store = getAccountStorage<any>({ name: 'channel-messages', topics: messageTopic, readonly: false }).Instance();
            const listener = store.query({
                filter: (m: ChannelMessage) => {
                    return m.channelId === channelId && coerceToDate(m.date) < archived;
                },
            }).on('load', async values => {
                const messageIdsToDelete = new Set<string>(values.map(m => m.id));
                await store.index.removeMany(id => messageIdsToDelete.has(id));
                listener.close();
                resolve(messageIdsToDelete);
            });
        });
    }

    private async getArchived(channelId: string): Promise<Date | null> {
        return (await this.getItem(channelId)).archived;
    }

    private async setArchived(channelId: string, date: Date) {
        await this.updateItem(channelId, {
            archived: date,
        });
    }

    private async updateItem(channelId: string, partialUpdate: Partial<ChannelArchiveItem>) {
        await this.storage.setMap(channelId, {
            ...(await this.getItem(channelId)),
            ...partialUpdate,
        });
    }

    private async getItem(channelId: string): Promise<ChannelArchiveItem> {
        const item = await this.storage.getMap(channelId);
        return {
            archived: (item?.archived as Date) ?? null,
        };
    }
}
