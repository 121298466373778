import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DragAndDropDirective } from 'components/dnd.directive';
import { IconComponent } from 'components/icon/icon.component';
import { AppIcon } from 'weavix-shared/models/icon.model';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss'],
    standalone: true,
    imports: [
        CommonModule,

        DragAndDropDirective,
        IconComponent,
    ],
})
export class ImageUploadComponent {
    @Input() imageSrc: string;
    @Input() readonly: boolean = false;
    @Input() iconPlaceHolder: AppIcon;
    @Output() imageChange = new EventEmitter<{ src: string, file: File }>();

    handleImageEdit(fileList: FileList): void {
        if (fileList instanceof FileList && !this.readonly) {
            const reader = new FileReader();
            reader.readAsDataURL(fileList[0]);
            reader.onload = (event) => {
                this.imageSrc = event.target.result as string;
                this.imageChange.emit({ src: this.imageSrc, file: fileList[0] });
            };
        }
    }

    handleInputChange(event: Event) {
        const target = event.target as HTMLInputElement;
        this.handleImageEdit(target.files);
    }

    removeImage(e: Event) {
        e.stopPropagation();

        if (!this.readonly) {
            this.imageSrc = null;
            this.imageChange.emit({ src: null, file: null });
        }
    }
}
