import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ConditionalKeys } from '@weavix/models/src/person/person-matcher';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';
import { ComplexConditionForm, SimpleConditionForm } from '../person-matcher.service';
import { SimpleConditionComponent } from '../simple-person-condition/simple-condition.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from 'components/icon/icon.component';
import { CommonModule } from '@angular/common';

@AutoUnsubscribe()
@Component({
    selector: 'app-complex-condition',
    templateUrl: './complex-condition.component.html',
    styleUrls: ['./complex-condition.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TranslateModule,

        IconComponent,
        SimpleConditionComponent,
    ],
})
export class ComplexConditionComponent implements OnInit {
    @Input() formGroup: ComplexConditionForm;
    @Input() defaultEmpty = false;
    @Input() nested = false;
    @Input() everyoneLabel?: string;
    @Output() remove = new EventEmitter<void>();

    ngOnInit() {
        if (!this.formGroup.controls.conditions.value.length && this.defaultEmpty) {
            this.add();
        }
    }

    get conditions() {
        return this.formGroup.controls.conditions as FormArray<FormGroup>;
    }

    get type() {
        return this.formGroup.controls.type;
    }

    get isAnd() {
        return this.type.value === 'and';
    }

    setType(type: 'and' | 'or') {
        this.type.setValue(type);
        this.formGroup.markAsDirty();
    }

    removeSimple(i: number) {
        this.conditions.removeAt(i);
        this.formGroup.markAsDirty();
        if (!this.conditions.controls.length) this.remove.emit();
    }

    removeComplex(i: number) {
        this.conditions.removeAt(i);
        this.formGroup.markAsDirty();
    }

    add(index?: number) {
        const personCondition: SimpleConditionForm = new FormGroup({
            key: new FormControl<ConditionalKeys>(ConditionalKeys.People),
            negate: new FormControl(false),
            value: new FormControl([] as string[]),
            self: new FormControl(false),
        });
        if (index == null) {
            this.conditions.push(new FormGroup({
                conditions: new FormArray<SimpleConditionForm | ComplexConditionForm>([personCondition]),
                type: new FormControl(this.type.value === 'and' ? 'or' : 'and'),
            }));
        } else if (this.nested) {
            this.conditions.push(personCondition);
        } else {
            this.conditions.setControl(index, new FormGroup({
                conditions: new FormArray([this.conditions.controls[index], personCondition]),
                type: new FormControl<'and' | 'or'>(this.type.value === 'and' ? 'or' : 'and'),
            }));
        }
        this.formGroup.markAsDirty();
    }
}
