import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';
import { DEFAULT_KEYS, StandardConditions } from '@weavix/models/src/person/person-matcher';
import { Subscription } from 'rxjs';
import { SimpleConditionComponent } from '../simple-person-condition/simple-condition.component';
import { CommonModule } from '@angular/common';

@AutoUnsubscribe()
@Component({
    selector: 'app-standard-matcher',
    templateUrl: './standard-matcher.component.html',
    styleUrls: ['./standard-matcher.component.scss'],
    standalone: true,
    imports: [
        CommonModule,

        SimpleConditionComponent,
    ],
})
export class StandardMatcherComponent implements OnInit, OnDestroy {
    @Input() formArray: FormArray<FormGroup>;
    @Input() includes = DEFAULT_KEYS;
    @Input() self = false;
    @Input() defaultEmpty = false;
    @Input() everyoneLabel?: string;

    loading = true;

    private subscription: Subscription;

    async ngOnInit() {
        if (this.defaultEmpty && !this.formArray.controls.length) {
            this.add();
        }
        this.loading = false;
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    get unusedCondition() {
        return Object.values(StandardConditions).find(value => {
            if (!this.includes.includes(value.key)) return false;

            return !this.formArray.controls.some(control =>
                control.get('key').value === value.key && control.get('negate').value === value.negate);
        });
    }

    remove(index: number) {
        this.formArray.removeAt(index);
        this.formArray.markAsDirty();
    }

    add() {
        const unused = this.unusedCondition;
        this.formArray.push(new FormGroup({
            key: new FormControl(unused.key),
            negate: new FormControl(unused.negate),
            value: new FormControl([]),
            self: new FormControl(false),
        }));
        this.formArray.markAsDirty();
    }
}
