import { configureStorage, model, Model, prop } from '@weavix/mobx';
import { SyncService } from '@weavix/sync';
import { memoize, throttle } from 'lodash';
import { autorun } from 'mobx';
import { WebNativeStorage } from 'weavix-shared/services/sync/web-native-storage';
import { ChannelsStore } from '../channels-store/channels-store';
import { CompaniesStore } from '../companies-store/companies-store';
import { CraftsStore } from '../crafts/crafts-store';
import { FacilityPeopleStore } from '../facility-people/facility-people-store';
import { MyProfileStore } from '../my-profile-store/my-profile-store';
import { PeopleStore } from '../people-store/people-store';
import { UsersStore } from '../users-store/users-store';
import { environment } from 'environments/environment';
import { FeatureFlagsStore } from 'models-mobx/feature-flags-store/feature-flags-store';
import { FeaturesStore } from 'models-mobx/feature-flags-store/features-store';
import { FacilitiesStore } from 'models-mobx/facilities-store/facilities-store';
import { UserSettingsStore } from 'models-mobx/user-settings-store/user-settings-store';

const storage = new WebNativeStorage('root-store');
configureStorage(WebNativeStorage);

export const getRootStore = memoize(() => new RootStore());

@model('RootStore')
export class RootStore extends Model({
    myProfileStore: prop(() => new MyProfileStore()),

    usersStore: prop(() => new UsersStore()),
    companiesStore: prop(() => new CompaniesStore()),

    // only need all channels in radio app
    channelsStore: prop(() => environment.radioApp ? new ChannelsStore() : null),
    facilityPeopleStore: prop(() => new FacilityPeopleStore()),

    peopleStore: prop(() => new PeopleStore()),
    craftsStore: prop(() => new CraftsStore()),
    facilitiesStore: prop(() => new FacilitiesStore()),
    featureFlagsStore: prop(() => new FeatureFlagsStore()),
    featuresStore: prop(() => new FeaturesStore()),
    // only need user settings in radio for favorite channels
    userSettingsStore: prop(() => environment.radioApp ? new UserSettingsStore() : null),
}) {
    close() {
        storage.clear();
    }

    async wait() {
        await Promise.all(Object.values(this).map(x => (x as any)?.loaded));
    }
}

SyncService.addTrigger(async user => {
    const rootStore = getRootStore();
    const persistedStores = [rootStore.myProfileStore];
    rootStore.myProfileStore.setUser(user);
    await Promise.all(persistedStores.map(async store => {
        const stored = await storage.getMap(`${store.constructor.name}-${user.id}`);
        if (store === rootStore.myProfileStore) console.log(store.constructor.name, stored);
        store.update(stored ?? {});
    }));

    const destroyers = persistedStores.map(store => {
        return autorun(throttle(() => {
            if (store === rootStore.myProfileStore) console.log(store.constructor.name, store.snapshot);
            storage.setMap(`${store.constructor.name}-${user.id}`, store.snapshot);
        }, 250));
    });
    return () => {
        destroyers.forEach(x => x());
        persistedStores.forEach(store => {
            storage.setMap(`${store.constructor.name}-${user.id}`, {});
        });
    };
});
