import { RichMarker } from '../map/map';
import { Person } from '../person/person';
import { PersonSelect } from '../person/person-select';
import { i18nLocale } from '../translate/translate';
import { AlertType } from './alert';

export interface FacilityAlertItem {
    id: string;
    alertType: AlertType;
    alertTypeId: string;
    alertLevel: FacilityAlertLevel;
    alertLocation?: {
        location?: number[];
        geofenceId?: string;
    };
    name: string;
    message: string;
    lastUpdated: Date;
    icon: string;
    total: number;
    totalRead: number;
    totalRemoved: number;
    progress: number;
    pinnedByUser?: boolean;
    location?: FacilityAlertLocation;
    marker?: RichMarker;
    sender?: Person;
    recipients?: PersonSelect[];
    acknowledged?: PersonSelect[];
    received?: PersonSelect[];
    totalAcknowledged?: number;
}

export enum FacilityAlertLevel {
    Danger = 'danger',
    Info = 'info',
    Warning = 'warning',
}

export enum FacilityAlertGroupKeys {
    Facility = 'facility',
    Geofence = 'geofence',
    Company = 'company',
    Crafts = 'crafts',
    Tags = 'tags',
}

export interface FacilityAlertRequest {
    created: Date;
    facilityId: string;
    alertType?: AlertType;
    alertTypeId: string;
    message: string;
    address?: string;
    sender?: string;
    companyIds?: string[];
    crafts?: string[];
    tags?: string[];
    location?: FacilityAlertLocation;
    alertLocation?: FacilityAlertAlertLocation;
    source?: {
        ruleId?: string;
    };
}

export interface FacilityAlertLocation {
    start: Date;
    end: Date;
    facilityIds?: string[];
    geofenceIds?: string[];
    currentlyIn?: boolean;
}

export interface FacilityAlertAlertLocation {
    location?: number[];
    geofenceId?: string;
}

export interface FacilityAlert extends FacilityAlertRequest {
    accountId?: string;
    id?: string;
    /** Reference to record in facilityAlerts collection */
    facilityAlertId?: string;
    /** Reference to record in notificationsContext */
    notificationId?: string;
    personId?: string;
    read?: Date;
    removed?: Date;
    dismissed?: Date;
    locale?: string;
    translations?: {
        [locale in i18nLocale]?: string;
    };
}

export interface FacilityAlertPersonUpdateRequest {
    read?: boolean;
    removed?: boolean;
}

export interface FacilityAlertResponse extends FacilityAlert {
    people?: string[];
}

export interface FacilityAlertPerson {
    id?: string;
    facilityAlertId?: string;
    personId?: string;
    accountId?: string;
    created?: Date;
    read?: Date;
    removed?: Date;
}

export type FacilityAlertPersonResponse = (FacilityAlertPerson & FacilityAlert) & {
    alertType: AlertType;
};

export interface FacilityAlertRecipients extends FacilityAlert {
    recipients?: FacilityAlertPerson[];
}

export interface FacilityAlertCreateResponse extends FacilityAlert {
    people: string[];
}
