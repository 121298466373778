import { Injectable } from '@angular/core';
import moment from 'moment';
import { BadgeUpdate } from '@weavix/models/src/badges/badge-update';
import { Person } from '../models/person.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { WaltStatus } from '../models/walt.model';
import { Walt, WaltResponse } from '@weavix/models/src/walt/walt';
import { MapWalt } from '../models/weavix-map.model';
import { AccountService } from './account.service';
import { HttpService } from './http.service';
import { PersonService } from './person.service';
import { PubSubService } from './pub-sub.service';
import { TranslationService } from './translation.service';
import { BatchMethod, BatchRequest, BatchResponse, BatchService } from './batch.service';
import { WaltConnectionLog } from '@weavix/models/src/walt/walt-connection-log';

@Injectable({
    providedIn: 'root',
})
export class WaltService {
    constructor(
        private personService: PersonService,
        private httpService: HttpService,
        private pubSubService: PubSubService,
        private accountService: AccountService,
        private translationService: TranslationService,
        private batchService: BatchService,
    ) { }

    static cacheCollection = 'walt';

    static readonly ACTIVE_WALT_TIME_MINUTES = 10;

    static url = (id?: string) => id ? `/track/walt/${id}` : `/track/walt`;

    static waltToBadgeUpdate(walt: MapWalt): BadgeUpdate {
        const personBadge = walt?.person?.badge;
        const waltLocation = { location: walt.location, level: walt.level };
        return personBadge ? { ...personBadge, ...waltLocation } : waltLocation;
    }

    static waltActive(walt: Walt, atTime?: number) {
        const activeTimeComparison: number = moment(atTime).subtract(this.ACTIVE_WALT_TIME_MINUTES, 'minutes').valueOf();
        const lastLocationUpdateTimestampIsActive: boolean = new Date(walt.locationUpdated ?? 1).getTime() > activeTimeComparison;
        const lastUpdateTimeStampIsActive: boolean = new Date(walt.updated ?? 1).getTime() > activeTimeComparison;

        return lastLocationUpdateTimestampIsActive || lastUpdateTimeStampIsActive; // within active time
    }

    static isCurrentlyAssigned(walt: Walt): boolean {
        if (!walt.lastAssigned?.personId
            || new Date(walt.lastAssigned?.date).getTime() < new Date(walt.wrangler?.date).getTime()
            || (walt.wrangler && walt.wrangler?.id)) return false;
        else return true;
    }

    static getWaltStatus(walt: Walt): WaltStatus {
        const assigned = this.isCurrentlyAssigned(walt);
        const active = this.waltActive(walt);
        if (walt.wrangler?.id) return WaltStatus.Docked;
        if (!assigned && (!active || walt.poweredOff)) return WaltStatus.NoConnectionUnassigned;
        if (!assigned) return WaltStatus.Unassigned;
        if (assigned && (!active || walt.poweredOff)) return WaltStatus.NoConnectionAssigned;
        if (assigned && !walt.loggedIn && !walt.poweredOff) return WaltStatus.PoweredOnLoggedOut;
        return WaltStatus.PoweredOnLoggedIn;
    }

    getWranglerSlotString(walt: Walt): string {
        let name: string = '';
        if (!walt.wrangler || !walt.wrangler.id) return '-';
        if (walt.wrangler.id) {
            name += walt.wrangler.id;
            if (walt.wrangler.slot) name += ` ${this.translationService.getImmediate('walt.slot', { id: walt.wrangler.slot })}`;
        }

        return name;
    }

    public getWaltLastAssignedName(walt: MapWalt): string {
        if (!WaltService.isCurrentlyAssigned(walt as Walt)) return '-';

        return walt.person?.fullName
            ? walt.person?.fullName
            : walt.lastAssigned?.personName
                ? walt.lastAssigned?.personName
                : walt.lastAssigned.personId
                    ? this.translationService.getImmediate('generics.unknown')
                    : this.translationService.getImmediate('walt.unassigned');
    }

    public getWaltFacility(walt: WaltResponse): string {
        return walt.lastAssigned?.facilityName ?? this.translationService.getImmediate('walt.unassigned');
    }

    public async getWaltLastAssignedPerson(component, walt: Walt): Promise<Person> {
        if (walt.lastAssigned?.personId) return await this.personService.getPerson(component, walt.lastAssigned?.personId);
        else return null;
    }

    getAllByFacility(component: any, facilityId: string) {
        return this.httpService.get<WaltResponse[]>(component, `${WaltService.url()}/facility/${facilityId}`);
    }

    getAllByAccount(component: any, accountId: string) {
        return this.httpService.get<WaltResponse[]>(component, `${WaltService.url()}/account/${accountId}`);
    }

    async remoteLogout(component: any, id: string) {
        return this.httpService.post<void>(component, `${WaltService.url(id)}/logout`, {});
    }

    async playWaltSound(component: any, id: string) {
        return this.httpService.post<void>(component, `${WaltService.url(id)}/playSound`, {});
    }

    async setWaltFacility(component: any, waltid: string, facilityId: string) {
        return this.httpService.post<void>(component, `${WaltService.url(waltid)}/assign`, { facilityId });
    }

    async bulkSetWaltFacilities(component: any, waltIds: string[], facilityId: string): Promise<BatchResponse[]> {
        const requests: BatchRequest[] = [];
        waltIds.forEach(id => {
            requests.push({
                path: `${WaltService.url(id)}/assign`,
                method: BatchMethod.POST,
                post: { facilityId },
            });
        });
        return await this.batchService.invoke(component, requests);
    }

    subscribeWaltUpdates(w: any) {
        return this.pubSubService.subscribe<WaltResponse>(w, Topic.AccountWaltUpdated, [this.accountService.getAccountId()]);
    }

    getWaltConnectionLogs(component: unknown, waltId: string) {
        return this.httpService.get<WaltConnectionLog[]>(component, `${WaltService.url(waltId)}/connection-logs`);
    }

    subscribeWaltConnectionLogs(component: unknown, waltId: string) {
        return this.pubSubService.subscribe<WaltConnectionLog>(component, Topic.WaltConnectionLogs, [waltId]);
    }
}
