import { PersonMatcher } from '@weavix/models/src/person/person-matcher';
import { PermissionAction } from '../permissions/permissions.model';
import { Taggable } from '@weavix/models/src/core/core';

export interface GroupExclusion {
    action: PermissionAction;
    facilityId: string;
    geofenceId?: string[];
}

export interface Group extends Taggable {
    id?: string;
    name?: string;
    grantedActions: PermissionAction[];
    exclusions?: GroupExclusion[];
    system?: boolean;
    canAssign?: boolean;
    unassignable?: boolean;
    directory?: PersonMatcher;
    livelook?: PersonMatcher;
    // represents an optional property that indicates the original source of a duplicated entity
    duplicateOf?: { facilityId: string; id: string };
}

export enum SystemGroup {
    GlobalAdmin = 'global-admin',
    None = 'none',
}
