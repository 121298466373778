import { FacilityAlertLevel } from '../alert/facility-alerts';
import { i18nLocale } from '../translate/translate';

export enum NotificationCenterType {
    FacilityAlert = 'facility-alert',
    MissedPage = 'missed-page',
    MissedCall = 'missed-call',
}

export const NotificationTypeCategories = {
    alerts: [NotificationCenterType.FacilityAlert],
    mentions: [NotificationCenterType.MissedPage],
};

export enum NotificationCenterAcknowledgement {
    Acknowledged = 'acknowledged',
    Accepted = 'accepted',
    Declined = 'declined',
    Canceled = 'canceled',
    Completed = 'completed',
    Closed = 'closed',
}

export interface NotificationCenterUpdate {
    read?: boolean;
    removed?: boolean;
    acknowledgement?: NotificationCenterAcknowledgement;
}

export interface NotificationCenterEntry {
    id: string;

    created: Date;
    read: Date;
    removed: Date;

    facilityId: string;
    personId: string;
    accountId: string;

    userId: string;
    senderId?: string;

    type: NotificationCenterType;

    dataId: any;
    acknowledgement?: NotificationCenterAcknowledgement;
    data: any;
    title?: string;
    message?: string;
    icon?: {
        value: string;
        color: string;
    };
    location?: [number, number];
    address?: string;
    translations?: {
        [locale in i18nLocale]?: string;
    };
    titleTranslations?: {
        [locale in i18nLocale]?: string;
    };
    alertLevel?: FacilityAlertLevel;

    translatedMessage?: string;
}

export const isValidNotificationType = (value: string): boolean => Object.values(NotificationCenterType).includes(value as any);
