import { sleep } from '@weavix/utils/src/sleep';

export const PRIORITY_QUEUE_INTERVAL_MS = 50;
export const PRIORITY_QUEUE_TIMEOUT_MS = 30_000;

export class PriorityQueue {
    private readonly running: Array<Promise<unknown>> = [];

    async run<T>(fn: () => Promise<T>): Promise<T> {
        const promise = fn();
        const withTimeout = Promise.race([promise, sleep(PRIORITY_QUEUE_TIMEOUT_MS)]);
        this.running.push(withTimeout);
        try {
            await withTimeout;
        } finally {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- We don't need to await the rest of the array.
            this.running.splice(this.running.indexOf(withTimeout), 1);
        }
        return await promise;
    }

    async queue<T>(fn: () => Promise<T>): Promise<T> {
        while (true) {
            await sleep(PRIORITY_QUEUE_INTERVAL_MS);
            if (!this.running.length) break;
            await Promise.all(this.running);
        }
        return await fn();
    }
}
