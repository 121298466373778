import { isPersonIncludedInAdminDefinedChannel } from '@weavix/domain/src/channel/adc';
import {
    computed,
    getAccountStorage,
    idProp,
    Model,
    model,
    modelAction,
    prop,
} from '@weavix/mobx';
import { Cache } from '@weavix/utils/src/cache';
import { sortAlphabetical } from '@weavix/utils/src/sort';
import { facilityPeopleContext } from '../facility-people/facility-people-store';
import { myProfileContext, myUserId } from '../my-profile-store/my-profile-store';
import { peopleContext, Person } from '../people-store/people-store';
import { myUser, User, userRef } from '../users-store/users-store';
import { ChannelMessage } from './channel-message';
import { ServiceLocator } from 'weavix-shared/services/service-locator';
import { AccessMap, ArchivedMap, ChannelConditionalOverride, ChannelDefaultFavorite, ChannelImageUrl, ChannelTask, ChannelType, ChannelTyper } from '@weavix/models/src/channel/channel';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { ChannelNotifications } from '@weavix/models/src/channel/channel-notifications';
import { ComplexPersonCondition } from '@weavix/models/src/person/person-matcher';

const getMessages = () => getAccountStorage('channel-messages').Instance().index;
const userCache = new Cache<User[]>({ duration: 60000, jitter: 60000 });
const accountCache = new Cache<Person[]>({ duration: 60000, jitter: 60000 });
const nameCache = new Cache<string>({ duration: 60000, jitter: 60000 });
const adcFields = [
    'people',
    'companies',
    'facilityIds',
    'peopleCrafts',
    'peopleTags',
    'excludedTags',
    'excludedPeople',
    'excludedCrafts',
    'excludedCompanies',
    'advancedCondition',
];

@model('Channel')
export class Channel extends Model({
    id: idProp,
    type: prop<ChannelType>(),
    lastMessage: prop<ChannelMessage>(),
    created: prop<string>(),
    updated: prop<string>(),
    name: prop<string>(),
    sequence: prop<number>(0),
    readSequence: prop<number>(0),
    readSequences: prop<Record<string, number>>(),
    companies: prop<string[]>([]),
    people: prop<string[]>([]),
    facilityIds: prop<string[]>(),
    peopleCrafts: prop<string[]>(),
    peopleTags: prop<string[]>(),
    excludedTags: prop<string[]>([]),
    excludedPeople: prop<string[]>([]),
    excludedCrafts: prop<string[]>([]),
    excludedCompanies: prop<string[]>([]),
    allPeople: prop<string[]>([]),
    pendingPeople: prop<string[]>(),
    creatorId: prop<string>(),
    notificationPreference: prop<ChannelNotifications>(),
    typers: prop<ChannelTyper[]>(() => []),
    accountId: prop<string>(),
    isReadOnly: prop<boolean>(),
    readOnlySenders: prop<string[]>(),
    favoriteIndex: prop<number>(),
    radioChannel: prop<number>(),
    isSnoozed: prop<boolean>(),
    conditionalNotificationOverrides: prop<ChannelConditionalOverride[]>(),
    defaultFavorite: prop<ChannelDefaultFavorite | null | undefined>(),
    task: prop<ChannelTask>(),

    advancedCondition: prop<ComplexPersonCondition>(),

    /**
     * The below fields have to do with channelType === ChannelType.AdminApi
     * channelImageUrl is a URL to an image that represents the channel.
     */
    channelImageUrl: prop<ChannelImageUrl>(),

    /**
     * - ChannelType.People: ArchivedMap
     * - ChannelType.Channel: Date (passed as a string)
     */
    archived: prop<ArchivedMap | string>(),
    /**
     * Due to issues with the sync layer, the `access` property can't be synced like others.
     * So we fetch it on demand (see `channel.component.ts`).
     * Since this is related to Data Retention rules which don't run often,
     * we're OK with this value not being reactive.
     */
    access: prop<AccessMap>(),
}) {
    temporary?: boolean;
    peopleSet: Set<string>;

    constructor(...args) {
        super(...args);
        if (args[0]?.people) {
            this.peopleSet = new Set(args[0].people);
        }
    }

    update(obj) {
        if (obj.people) this.peopleSet = new Set(obj.people);
        if (this.type === ChannelType.Channel && Object.keys(obj).some(x => adcFields.includes(x))) userCache.clear(this.id);
        // Fixing badge flicker on race condition on mobx events
        if (obj.lastMessage?.senderId === myUserId() && obj.lastMessage.sequence) obj.readSequence = obj.lastMessage.sequence;
        // if we locally overrode something, assume it will be up-to-date soon in the backend
        obj.readSequences = { ...this.readSequences, ...obj.readSequences };
        obj.notificationPreference = { ...this.notificationPreference, ...obj.notificationPreference };
        super.update(obj);
    }

    get readOnly() {
        return this.isReadOnly && !this.readOnlySenders?.includes(myProfileContext.get(this).userId)
            || this.radioChannel
            || this.isReadOnly && this.type === ChannelType.SupportTicket;
    }

    get isNotebook() {
        // Notebook could also be a 1-user People channel.
        // TODO(post-5.19): clean up.
        return (this.type === ChannelType.People || this.type === ChannelType.Notebook)
            && this.people.includes(myUserId())
            && this.allPeople.length <= 1
            && !this.temporary;
    }

    @computed
    get displayName() {
        if (this.name) return this.name;
        let cached = nameCache.get(this.id);
        if (cached) return cached;

        if (this.otherUsers.length === 0) {
            if (this.isNotebook) {
                return `${myUser().fullName ?? `${myUser().firstName} ${myUser().lastName}`} (${ServiceLocator.get(TranslationService).getImmediate('generics.me')})`;
            } else if (this.temporary) {
                return ServiceLocator.get(TranslationService).getImmediate('crews.channels.channel-details');
            } else {
                return ServiceLocator.get(TranslationService).getImmediate('generics.unknown-user');
            }
        } else {
            const names = this.otherUsers.slice(0, 3)
                .map(user => user.fullName ?? `${user.firstName} ${user.lastName}`)
                .join(', ');
            const remaining = this.otherUsers.length - 3;
            const count = remaining > 0 ? ` +${remaining}` : '';
            cached = `${names}${count}`;
        }
        // if temporary, don't cache name as some may be added
        if (this.temporary) return cached;
        else return nameCache.set(this.id, cached);
    }

    get users() {
        if (this.type === ChannelType.People) {
            const list = userCache.get(this.id);
            if (list) return list;
            // if temporary, don't cache users as some may be added
            if (this.temporary) return this.people.map(p => userRef(p).maybeCurrent);
            else return userCache.set(this.id, sortAlphabetical(this.people.map(p => userRef(p).maybeCurrent).filter(x => x), 'lastName'));
        }

        const cached = userCache.get(this.id);
        if (cached) return cached;

        const isPersonOnFacility = (facilityId: string, person: Person) => !!facilityPeopleContext.get().getPerson(person.id, facilityId);

        let allPeople = accountCache.get(this.accountId);
        if (!allPeople) {
            allPeople = accountCache.set(this.accountId, peopleContext.get().getAllStatic({ accountId: this.accountId }).filter(x => x.user));
        }

        const includedPeople = allPeople.filter(p => isPersonIncludedInAdminDefinedChannel(p, this, isPersonOnFacility));
        return userCache.set(this.id, sortAlphabetical(includedPeople.map(p => userRef(p.id).maybeCurrent).filter(x => x), 'lastName'));
    }

    @computed
    get pendingUsers() {
        return this.pendingPeople.map(x => userRef(x).maybeCurrent).filter(x => x) as User[];
    }

    get usersWithPendingUsers() {
        return this.users.concat(this.pendingUsers) as User[];
    }

    get otherUsers() {
        return this.users?.filter(x => x.id !== myProfileContext.get(this).userId);
    }

    get sortedOtherUserIds(): string[] {
        return this.otherUsers.map(x => x.id).sort();
    }

    @computed
    get creator() {
        return userRef(this.creatorId).maybeCurrent;
    }

    @computed
    get userNamesTitle(): string {
        const users = this.otherUsers;
        return users.length === 1 ? users[0].fullName : users.map(x => x.firstName).join(', ');
    }

    @computed
    get pendingUserNamesTitle(): string {
        const users = this.pendingUsers;
        return users.length === 1 ? users[0].fullName : users.map(x => x.firstName).join(', ');
    }

    @computed
    get fullTitle(): string {
        return this.name?.length ? this.name : this.userNamesTitle;
    }

    @computed
    get searchText() {
        return `${this.name} ${this.users.map(x => x.searchText).join(' ')}`?.toLowerCase();
    }

    @computed
    get unreadCount() {
        return Math.max(0, this.sequence - this.readSequence);
    }

    @computed
    get channelLanguages(): string[] {
        const languages = [];
        this.users.forEach(x => {
            if (x.locale && !languages.includes(x.locale)) languages.push(x.locale);
        });
        return languages;
    }

    removePending(pending: ChannelMessage) {
        getMessages().remove(pending.id);
    }

    @modelAction
    setReadSequence(readSequence: number) {
        this.readSequence = readSequence;
    }

    invalidateUserCache() {
        userCache.clear(this.id);
    }
}
