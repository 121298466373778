
/* Directives */
import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from 'weavix-shared/directives/click-outside.directive';
import { PanDirective } from 'weavix-shared/directives/pan.directive';
import { ResizeDirective } from 'weavix-shared/directives/resize.directive';
import { ZoomDirective } from 'weavix-shared/directives/zoom.directive';

/* Pipes */
import { LinkifyPipe } from 'weavix-shared/pipes/linkify.pipe';
import { ObjectKeysPipe } from 'weavix-shared/pipes/object-keys.pipe';
import { SafeHtmlPipe } from 'weavix-shared/pipes/safe-html.pipe';
import { ShortNumberPipe } from 'weavix-shared/pipes/short-number.pipe';
import { TimeAgoPipe } from 'weavix-shared/pipes/time-ago.pipe';
import { HtmlEncode } from './pipes/html-encode.pipe';


@NgModule({
    declarations: [
        LinkifyPipe,
        HtmlEncode,
        SafeHtmlPipe,
        ShortNumberPipe,
        ObjectKeysPipe,
        ZoomDirective,
        PanDirective,
        ResizeDirective,
    ],
    imports: [
        ClickOutsideDirective,
        TimeAgoPipe,
    ],
    exports: [
        ClickOutsideDirective,
        LinkifyPipe,
        HtmlEncode,
        SafeHtmlPipe,
        ShortNumberPipe,
        TimeAgoPipe,
        ZoomDirective,
        PanDirective,
        ResizeDirective,
        ObjectKeysPipe,
    ],
})
export class WeavixSharedModule { }
