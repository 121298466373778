import { Context, createStore, ExtendedModel, model } from '@weavix/mobx';
import { Facility } from './facility-model';
import { getPerson } from 'models-mobx/people-store/people-store';
import { myUserId } from 'models-mobx/my-profile-store/my-profile-store';
import { SystemGroup } from 'weavix-shared/models/group.model';
import { getFacilityPerson } from 'models-mobx/facility-people/facility-people-store';

const base = createStore<Facility>('facilities', Facility);

export const facilitiesContext = base.context as Context<FacilitiesStore>;
export const facilityRef = base.ref;

@model('FacilitiesStore')
export class FacilitiesStore extends ExtendedModel(base.Store, {
}) {
    getAll(query?) {
        const list = super.getAll(query) as Facility[];
        return list.filter(facility => {
            return getPerson(facility.accountId, myUserId())?.group === SystemGroup.GlobalAdmin
                || getFacilityPerson(facility.id) != null;
        });
    }
}
