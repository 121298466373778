import { Context, createStore, ExtendedModel } from '@weavix/mobx';
import { FavoriteChannelSetting, UserSettingName } from '@weavix/models/src/user/UserSettings';
import { UserSettings } from './user-settings';

const base = createStore<UserSettings>('settings', UserSettings);

export const userSettingsContext = base.context as Context<UserSettingsStore>;
export const userSettingsRef = base.ref;

export class UserSettingsStore extends ExtendedModel(base.Store, {}) {
    get favoriteChannels(): FavoriteChannelSetting[] {
        return this.get(UserSettingName.FavoriteChannels)?.settings ?? [];
    }
}
